import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CubeClientService } from 'src/app/shared/services/cube-client.service';
import { ReportPage, ReportLogo, LogoMovias } from '../report.service';
import { from, concat } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, OnChanges {

  @Input()
  showToolbar: boolean = true;

  @Input()
  btnTrashDisabled: boolean = false;

  @Input()
  ownerId: number = 0;

  @Input()
  title: string = '';

  @Input()
  selectedPage: ReportPage | null = null;

  @Output()
  onBtnAddClick: EventEmitter<ReportPage> = new EventEmitter<ReportPage>();

  @Output()
  onBtnRemoveClick: EventEmitter<ReportPage> = new EventEmitter<ReportPage>();

  logo_url: string = '';

  @Input()
  startDate: DateTime = DateTime.utc();

  @Input()
  endDate: DateTime = DateTime.utc();

  private _logo: ReportLogo = { image: "", format: "" };

  get logo() : ReportLogo {
    return this._logo;
  }

  constructor(private _cubeClientService: CubeClientService, public _domSanitizer: DomSanitizer) { 

  }

  ngOnInit(): void {
    this.loadLogo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['ownerId'] && !changes['ownerId'].firstChange)
    {
      this.loadLogo();
    }
  }

  private loadLogo() {
    concat(
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["ClientsLogo.count"],
        dimensions: ["ClientsLogo.image", "ClientsLogo.format"],
        filters: [
          { dimension: "Clients.id", operator: "equals", values: [this.ownerId.toString()] }
        ],
        segments: [],
      }))
    )
    .subscribe({
      next: (resultSet) => {
        const results = resultSet.tablePivot();
        if(resultSet.annotation().measures["ClientsLogo.count"])
        {
          this._logo = 
            (Array.isArray(results) && (results.length > 0)) ?
            { image: results[0]["ClientsLogo.image"].toString(), format: results[0]["ClientsLogo.format"].toString() }
            :
            LogoMovias
          this.logo_url = `data:image/${this._logo.format};base64,${this._logo.image}`;
        }
        else
        {
          // Nada aqui
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onAdd() {
    if(this.onBtnAddClick && this.selectedPage)
      this.onBtnAddClick.emit(this.selectedPage);
  }

  onRemove() {
    if(this.onBtnRemoveClick && this.selectedPage)
      this.onBtnRemoveClick.emit(this.selectedPage);
  }

  getStartDate() {
    return this.startDate.toLocaleString(DateTime.DATE_SHORT);
  }

  getEndDate() {
    return this.endDate.toLocaleString(DateTime.DATE_SHORT);
  }

}
