import { Component, ViewChild, Input } from '@angular/core';
import { DxPolarChartComponent } from 'devextreme-angular';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults, measures, dimensions } from '../../report.service';

@Component({
  selector: 'app-report-panel-radar',
  templateUrl: './report-panel-radar.component.html',
  styleUrls: ['./report-panel-radar.component.scss']
})
export class ReportPanelRadarComponent extends ReportPanelBaseComponent {

  data: any[] = [];
  series: string[] = [];

  @ViewChild(DxPolarChartComponent, { static: true })
  private dxChart!: DxPolarChartComponent;

  constructor() {
    super();
    this.customizeLegend = this.customizeLegend.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.customizePoint = this.customizePoint.bind(this);
  }

  customizeLegend(arg: any) {
    if(this.query.dimensions.length == 0)
    {
      return arg.seriesName || "N/A";
    }
    else
    {
      const d = dimensions.find((dimension) => dimension.value == this.query.dimensions[0]);
      const dFormat = d.format.formatValue(arg.seriesName);
      const argumentText = dFormat.value + " " + dFormat.unit;
      return argumentText;
    }
  }

  customizeTooltip(info: any) {
    const m =  measures.find((measure) => measure.value == this.query.measures[0]);
    const format = m.format.formatValue(info.value);
    const valueText = format.value + " " + format.unit;
    if(this.query.dimensions.length == 0)
    {
      return {
        html: `
          <div>
            <div class='tooltip-header'>${info.argumentText}</div>
            <div class=\'tooltip-body\'>
              <div class=\'series-name\'>
                <div class='top-series-name'>Total</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
              </div>
            </div>
          </div>
        `,
      }
    }
    else
    {
      const d = dimensions.find((dimension) => dimension.value == this.query.dimensions[0]);
      const dFormat = d.format.formatValue(info.seriesName);
      const argumentText = dFormat.value + " " + dFormat.unit;
      return {
        html: `
          <div>
            <div class='tooltip-header'>${info.argumentText}</div>
            <div class=\'tooltip-body\'>
              <div class=\'series-name\'>
                <div class='top-series-name'>${argumentText}</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
              </div>
            </div>
          </div>
        `,
      }
    }
  }

  customizePoint(arg: any) {
    return {
      color: measures.find((measure) => measure.value == this.query.measures[arg.index])?.color,
    }
  }

  override export(): void {
    this.dxChart.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.data = [];
    this.series = [];
  }

  getMeasureName(m: string) {
    return measures.find((measure) => measure.value == m)?.label || "";
  }

  override redraw(results: ReportQueryResults): void {
    this.data = [];
    this.series = ((this.query.dimensions.length > 0) ? [...new Set(results.map((item: any) => item[this.query.dimensions[0]]))] : ["Series 1"]).filter((item: any) => item != null);
    this.query.measures.forEach((measure: string) => {

      const row: any = {};
      row.arg = this.getMeasureName(measure);

      this.series.forEach((serie: string) => {
        const item: any = results.find((item: any) => item[this.query.dimensions[0]] == serie);
        if(item)
          row[serie] = parseFloat(item[measure]?.toString() || "0");
        else
          row[serie] = parseFloat(results[0][measure]?.toString() || "0");
      });

      this.data.push(row);
    });
  }

}
