import { Component, ViewChild } from '@angular/core';
import { DxLinearGaugeComponent } from 'devextreme-angular';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults } from '../../report.service';

@Component({
  selector: 'app-report-panel-gauge-linear',
  templateUrl: './report-panel-gauge-linear.component.html',
  styleUrls: ['./report-panel-gauge-linear.component.scss']
})
export class ReportPanelGaugeLinearComponent extends ReportPanelBaseComponent  {

  total: number = 0;

  @ViewChild(DxLinearGaugeComponent, { static: true })
  private gauge!: DxLinearGaugeComponent;

  constructor() {
    super();
    this.customizeLabel = this.customizeLabel.bind(this);
  }

  customizeLabel(arg: any) {
    return `${arg.valueText} ${this.chart.linearGaugeOptions.unit || ''}`;
  }

  override export(): void {
    this.gauge.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.total = 0;
  }

  override redraw(results: ReportQueryResults): void {
    this.total = 0;
    for(var i = 0; i < results.length; i++) {
      var row = results[i];
      this.total += parseFloat(row[this.query.measures[0]].toString());
    }
  }

}

