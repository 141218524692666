
import { Injectable, Optional, Inject } from '@angular/core';
import cubejs, { CubeApi } from '@cubejs-client/core';
import { environment } from 'src/environments/environment';

const API_URL = environment.CUBEJS_API_URL;
const API_TOKEN = environment.API_TOKEN;

@Injectable()
export class CubeClientService {
  
  private _cubejsApi:CubeApi | undefined;

  constructor() {
    
  }


  getCubejsApi() : CubeApi {
    let token = API_TOKEN;
    let apiUrl = API_URL;
    if (token) {
      this._cubejsApi = cubejs(token,
        { apiUrl: `${apiUrl}/cubejs-api/v1` }
      );
      return this._cubejsApi;
    }
    throw new Error("Token cubejs error.");
  }
}
