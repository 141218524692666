import { Component, ViewChild, Input } from '@angular/core';
import { DxPieChartComponent } from 'devextreme-angular';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults, measures, dimensions, segments } from '../../report.service';

@Component({
  selector: 'app-report-panel-pie',
  templateUrl: './report-panel-pie.component.html',
  styleUrls: ['./report-panel-pie.component.scss']
})
export class ReportPanelPieComponent extends ReportPanelBaseComponent {

  data: any = {};

  @Input()
  type: 'pie' | 'doughnut' = "pie";

  @ViewChild("chart", { static: false })
  private dxChart!: DxPieChartComponent;

  constructor() {
    super();
    this.customizeLegend = this.customizeLegend.bind(this);
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizeText = this.customizeText.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.customizePoint = this.customizePoint.bind(this);
  }

  customizeLabel(arg: any) {
    const m =  measures.find((measure) => measure.value == arg.seriesName) || measures.find((measure) => measure.value == arg.argument);
    const format = m.format.formatValue(arg.value);
    const valueText = format.value + " " + format.unit;
    return `${valueText} (${arg.percentText})`;
  }

  customizeLegend(arg: any) {
    if(this.query.dimensions.length == 0)
    {
      return arg.pointName || "N/A";
    }
    else
    {
      const d = dimensions.find((dimension) => dimension.value == this.query.dimensions[0]);
      const dFormat = d.format.formatValue(arg.pointName);
      const argumentText = dFormat.value + " " + dFormat.unit;
      return argumentText;
    }
  }

  customizeTooltip(info: any) {
    const m =  measures.find((measure) => measure.value == info.seriesName) || measures.find((measure) => measure.value == info.argument);
    const mFormat = m.format.formatValue(info.value);
    const valueText = mFormat.value + " " + mFormat.unit;
    if(this.query.dimensions.length == 0)
    {
      return {
        html: `
          <div>
            <div class='tooltip-header'>${this.title}</div>
            <div class=\'tooltip-body\'>
              <div class=\'series-name\'>
                <div class='top-series-name'>${m.label}</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
              </div>
            </div>
          </div>
        `,
      }
    }
    else
    {
      const d = dimensions.find((dimension) => dimension.value == this.query.dimensions[0]);
      const dFormat = d.format.formatValue(info.argumentText);
      const argumentText = dFormat.value + " " + dFormat.unit;
      return {
        html: `
          <div>
            <div class='tooltip-header'>${m.label}</div>
            <div class=\'tooltip-body\'>
              <div class=\'series-name\'>
                <div class='top-series-name'>${argumentText}</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
              </div>
            </div>
          </div>
        `,
      }
    }
  }

  customizeText(arg: any) {
    return measures.find((measure) => measure.value == arg.pointName).label;
  }

  customizePoint(arg: any) {
    return {
      color: measures.find((measure) => measure.value == arg.argument)?.color,
    }
  }

  override export(): void {
    this.dxChart.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.data = {};
  }

  override redraw(results: ReportQueryResults): void {
    if(this.query.dimensions.length == 0)
    {
      this.data = [];
      results.forEach((row: any) => {
        this.query.measures.forEach((measure: any) => {
          this.data.push({
            arg: measure,
            val: row[measure],
          });
        });
      });
    }
    else
    {
      // Filtramos N/As
      this.data = results.filter((row: any) => row[this.query.dimensions[0]] != null);
    }
  }

}
