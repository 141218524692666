<dx-chart #dxChart class="panel-line" [dataSource]="data" palette="pastel" *ngIf="query.measures.length == 1">
  <dxo-common-series-settings [argumentField]="timeDimension" [valueField]="query.measures[0]" [type]="chart.lineOptions.type">
  </dxo-common-series-settings>   
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>
  <dxo-series-template *ngIf="query.dimensions.length == 1" [nameField]="query.dimensions[0]"></dxo-series-template>
  <dxi-series *ngIf="query.dimensions.length == 0" [name]="getMeasureName(query.measures[0])"></dxi-series>
  <dxo-argument-axis>
      <dxo-label wordWrap="none" overlappingBehavior="stagger"></dxo-label>
  </dxo-argument-axis>
  <dxi-value-axis>
      <dxo-grid [visible]="true"></dxo-grid>
      <dxo-label [visible]="true" format="largeNumber" [customizeText]="customizeLabel"></dxo-label>
    </dxi-value-axis>
  <dxo-animation easing="easeOutCubic" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
  <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="10000"></dxo-tooltip>
</dx-chart>

<dx-chart #dxChart class="panel-line" [dataSource]="data" palette="pastel" *ngIf="query.measures.length > 1">
  <dxo-common-series-settings [argumentField]="timeDimension" [type]="chart.lineOptions.type">
  </dxo-common-series-settings>
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>
  <dxi-series *ngFor="let m of query.measures;" [valueField]="m" [name]="getMeasureName(m)" [color]="getColor(m)"></dxi-series>
  <dxo-argument-axis>
      <dxo-label wordWrap="none" overlappingBehavior="stagger"></dxo-label>
  </dxo-argument-axis>
  <dxi-value-axis>
      <dxo-grid [visible]="true"></dxo-grid>
      <dxo-label [visible]="true" format="largeNumber" [customizeText]="customizeLabel"></dxo-label>
  </dxi-value-axis>
  <dxo-animation easing="easeOutCubic" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
  <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="10000"></dxo-tooltip>
</dx-chart>