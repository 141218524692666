import { Component, Input, Output, OnInit, enableProdMode, EventEmitter, ViewChild, ViewChildren, AfterViewInit, QueryList, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ReportSettings, ReportToolbarSettings, ReportToolbarOwner, ReportPanelSettings, ReportPageLayout, ReportPage, ReportQuery, TimeDimensionGranularities, ReportPanelModels, ReportService, LogoMovias } from './report.service';
import { ReportToolbarComponent, ReportToolbarFilters } from './report-toolbar/report-toolbar.component';
import { ReportLayoutDesignerComponent, ReportLayoutDesignerEvent } from './report-layout-designer/report-layout-designer.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ReportEditorComponent } from './report-editor/report-editor.component';
import { CubeClientService } from 'src/app/shared/services/cube-client.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { from, fromEvent, concat, switchMap, tap } from 'rxjs';
import { DateTime } from 'luxon';
import * as FileSaver from 'file-saver';
const { v4: uuid } = require('uuid');
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-report-designer',
  templateUrl: './report-designer.component.html',
  styleUrls: ['./report-designer.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [
        animate('250ms ease-out')
      ]),
      transition('out => in', [
        animate('500ms ease-in')
      ]),
    ])
  ]
})
export class ReportDesignerComponent implements OnInit, AfterViewInit {

  @ViewChild('toolbar', { static: true })
  toolbar!: ReportToolbarComponent;
  @ViewChild('layout')
  layout!: ReportLayoutDesignerComponent;
  @ViewChild('editor', { static: true })
  editor!: ReportEditorComponent;
  @ViewChild('formReportSettings', { static: true })
  formReportSettings!: DxFormComponent;
  @ViewChild('viewerReportPopup', { static: false })
  previewReportPopupElement!: ElementRef;
  @ViewChild('viewerReportPage', { static: false }) 
  previewReportPageElement!: ElementRef;
  @ViewChild(ReportHeaderComponent, { static: false })
  reportHeader!: ReportHeaderComponent;

  @Input()
  editable: boolean = true;
  @Input()
  settings: ReportSettings = new ReportSettings();
  @Output()
  onLoadingStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onLoadingFinished: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBackClick: EventEmitter<any> = new EventEmitter<any>();

  settingsPopupVisible: boolean = false;
  editorPopupVisible: boolean = false;
  viewerPanelPopupVisible: boolean = false;
  viewerReportPopupVisible: boolean = false;
  confirmRemovePagePopupVisible: boolean = false;
  confirmRemovePanelPopupVisible: boolean = false;
  confirmSaveAndExitPopupVisible: boolean = false;
  isExporting: boolean = false;

  viewerPanelSettings: ReportPanelSettings = new ReportPanelSettings();
  editorPanelSettings: ReportPanelSettings = new ReportPanelSettings();
  previewReportSettings: ReportSettings = new ReportSettings();
  selectedPanel: ReportPanelSettings = new ReportPanelSettings();

  selectedPage!: ReportPage;
  currentPageIdx: number = 0;

  clientsDataSource: DataSource;
  title: string = "Meu Relatório";
  today: DateTime = DateTime.now();

  pageFadeState: 'in' | 'out' = 'in';
  pageContentVisible: boolean = true;

  get isLoadingAnyPanel(): boolean {
    return this.settings.pages.some((page: ReportPage) => page.panels.some((panel: ReportPanelSettings) => panel.loading));
  }

  constructor(private _reportService: ReportService, private _cubeClientService: CubeClientService, public _domSanitizer: DomSanitizer) {
    this.clientsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: [],
      }),
      paginate: true,
      pageSize: 10
    });
  }

  ngOnInit(): void {
    this.selectedPage = this.settings.pages[0];
    this.settings.filters.startDate = DateTime.utc().startOf("day");
    this.settings.filters.endDate = DateTime.utc().endOf("day");
    if(this.settings.toolbar.owner.id != 0)
    {
      this.settings.filters.queryFilters.push({ dimension: "Clients.id", operator: "equals", values: [this.settings.toolbar.owner.id.toString()] });
    }
    this.settings.pages.forEach((page: ReportPage) => {
      page.panels.forEach((panel: ReportPanelSettings) => {
        if(panel.query.usesTimeDimensions())
        {
          panel.query.timeDimensions[0].dimension = panel.query.timeDimensionName;
          panel.query.timeDimensions[0].dateRange = [this.settings.filters.startDate.toISODate(), this.settings.filters.endDate.toISODate()];
        }
        else
        {
          panel.query.timeDimensions = [];
        }
      });
    });
    concat(
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["Myself.count"],
        dimensions: ["Clients.id", "Clients.name"],
        filters: [],
        segments: [],
      })),
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["Clients.count"],
        dimensions: ["Clients.id", "Clients.name"],
        filters: [],
        segments: [],
      }))
    )    
    .subscribe({
      next: (resultSet) => {
        const results = resultSet.tablePivot();
        if(resultSet.annotation().measures["Myself.count"])
        {
          if(this.settings.toolbar.owner.id == 0 && results.length > 0)
          {
            // check if clients.id is null
            if(results[0]["Clients.id"] == null)
            {
              this.settings.toolbar.owner.id = 0;
              this.settings.toolbar.owner.name = "";
              this.toolbar.reload();
              this.title = `${this.settings.toolbar.title}`;
            }
            else
            {
              this.settings.toolbar.owner.id = parseInt(results[0]["Clients.id"].toString());
              this.settings.toolbar.owner.name = results[0]["Clients.name"].toString();
              this.toolbar.ownerChanged();
              this.title = `${this.settings.toolbar.title} (${this.settings.toolbar.owner.name})`;
            }
          }
          else
          {
            this.toolbar.ownerChanged();
            this.title = `${this.settings.toolbar.title} (${this.settings.toolbar.owner.name})`;
          }
        }
        else if(resultSet.annotation().measures["Clients.count"])
        {
          this.clientsDataSource = new DataSource({
            store: new ArrayStore({
              key: "id",
              data: results.map((row: any) => { 
                return { id: parseInt(row["Clients.id"]), name: row["Clients.name"] }
              }),
            }),
            paginate: true,
            pageSize: 10
          });
        }
        else
        {
          // Nada aqui
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  ngAfterViewInit(): void {
    
  }

  animatePageTransition() {
    this.pageFadeState = 'out';
    this.pageContentVisible = false;
    setTimeout(() => {
      this.pageFadeState = 'in';
      setTimeout(() => {
        this.pageContentVisible = true;
        this.layout.resize();
      }, 500);  // Tempo para animação de fade in
    }, 250);    // Tempo para animação de fade out
  }

  private runQuery(pnl: ReportPanelSettings) {

    pnl.loading = true;
    pnl.results = [];

    if(!pnl.query.isValid(pnl.chart.model)) {
      pnl.loading = false;
      return;
    }

    // Setting the date range for the query and
    // Making sure we do not have any null granularities
    const timeDimensions = pnl.query.timeDimensions?.map(td => {
      const dateRange = [this.settings.filters.startDate.toISODate(), this.settings.filters.endDate.toISODate()] as [string, string];
      if (typeof td.granularity === "string") {
        return { dimension: td.dimension, granularity: td.granularity, dateRange: dateRange };
      }
      return { dimension: td.dimension, dateRange: dateRange };
    });

    from(this._cubeClientService.getCubejsApi().load({
      measures:       pnl.query.measures,
      dimensions:     pnl.query.dimensions,
      timeDimensions: timeDimensions,
      segments:       pnl.query.segments,
      filters:        [...this.settings.filters.queryFilters, ...pnl.query.filters],
      timezone:       Intl.DateTimeFormat().resolvedOptions().timeZone,
      limit:          pnl.query.limit,
      order:          pnl.query.order,
    }))
    .subscribe({
      next: (resultSet: any) => {
        if(resultSet) {
          pnl.results = resultSet.tablePivot();
        } else {
          pnl.results = [];
        }
        pnl.loading = false;

        // Se já carregamos todos os paineis, emitimos evento de finalização
        const isLoading = this.settings.pages.some((page: ReportPage) => page.panels.some((panel: ReportPanelSettings) => panel.loading));
        if(!isLoading)
        {
          if(this.onLoadingFinished)
            this.onLoadingFinished.emit();
        }
      },
      error: (err: any) => {
        pnl.results = [];
        pnl.loading = false;
        console.log("Error:", err);
      }
    });
  }

  onBtnRefreshClick(e: any) {
    if(this.onLoadingStarted)
      this.onLoadingStarted.emit();
    this.settings.pages.forEach((page: ReportPage) => {
      page.panels.forEach((panel: ReportPanelSettings) => {
        this.runQuery(panel);
      });
    });
    this.today = DateTime.now();
  }

  onBtnSettingsClick(e: any) {
    this.settingsPopupVisible = true;
  }

  onFilterChanged(e: ReportToolbarFilters) {
    this.settings.filters.startDate = e.startDate;
    this.settings.filters.endDate = e.endDate;
    this.settings.filters.queryFilters = e.filters;
    this.settings.pages.forEach((page: ReportPage) => {
      page.panels.forEach((panel: ReportPanelSettings) => {
        if(panel.query.usesTimeDimensions())
        {
          if(panel.query.timeDimensions.length == 0)
          {
            panel.query.timeDimensions = [
              { dimension: panel.query.timeDimensionName, dateRange: [this.settings.filters.startDate.toISODate(), this.settings.filters.endDate.toISODate()] }
            ];
          }
          if(panel.chart.isTimeSeries())
          {
            panel.query.timeDimensions[0].granularity = panel.query.timeDimensions[0].granularity ?? TimeDimensionGranularities.DAY;
          }
          panel.query.timeDimensions[0].dimension = panel.query.timeDimensionName;
          panel.query.timeDimensions[0].dateRange = [this.settings.filters.startDate.toISODate(), this.settings.filters.endDate.toISODate()];
        }
        else
        {
          panel.query.timeDimensions = [];
        }
      });
    });
  }

  onBtnAddClick(e: any) {
    this.settings.pages = [...this.settings.pages, new ReportPage({ layout: this.settings.toolbar.defaultPageLayout, size: this.settings.toolbar.defaultPageSize })];
    this.currentPageIdx = this.settings.pages.length - 1;
    this.selectedPage = this.settings.pages[this.currentPageIdx];
    this.animatePageTransition();
  }

  onPanelAddClick(e: ReportPage) {
    this.selectedPage = e;
    this.editorPanelSettings = new ReportPanelSettings();
    this.editorPopupVisible = true;
  }

  onPageRemoveClick(e: ReportPage) {
    this.selectedPage = e;
    this.confirmRemovePagePopupVisible = true;
  }

  onPageRemoveOkClick(e: any) {
    this.settings.pages = this.settings.pages.filter((page: ReportPage) => page.id != this.selectedPage.id);
    const idx = Math.min(this.currentPageIdx, this.settings.pages.length - 1);
    if(idx != this.currentPageIdx) {
      this.currentPageIdx = idx;
      this.selectedPage = this.settings.pages[this.currentPageIdx];
      this.animatePageTransition();
    }
    this.confirmRemovePagePopupVisible = false;
  }

  onPageRemoveCancelClick(e: any) {
    this.confirmRemovePagePopupVisible = false;
  }

  onBtnToggleLayoutClick(e: ReportPageLayout) {
    this.settings.pages.forEach((page: ReportPage) => {
      page.layout = e;
    });
    this.layout.resize();
  }

  onBtnPreviewClick(e: any) {
    this.previewReportSettings = new ReportSettings(this.settings);
    this.viewerReportPopupVisible = true;
  }

  onPreviewPanelShown(e: any) {
    this.rescaleReportViewerElement();
  }

  onBtnSettingsOkClick(e: any) {
    this.settingsPopupVisible = false;
    this.toolbar.ownerChanged();
    const owner: any = this.formReportSettings.instance.getEditor("owner.id")?.option("selectedItem");
    this.title = `${this.settings.toolbar.title} (${owner.name})`;
  }

  onPanelRemoveClick(evt: ReportLayoutDesignerEvent) {
    this.selectedPanel = evt.panel;
    this.selectedPage = evt.page;
    this.confirmRemovePanelPopupVisible = true;
  }

  onPanelRemoveOkClick(e: any) {
    this.layout.removePanel(this.selectedPanel);
    this.confirmRemovePanelPopupVisible = false;
  }

  onPanelRemoveCancelClick(e: any) {
    this.confirmRemovePanelPopupVisible = false;
  }

  onPanelEditClick(evt: ReportLayoutDesignerEvent) {
    this.selectedPage = evt.page;
    this.editorPanelSettings = new ReportPanelSettings(evt.panel);
    this.editorPopupVisible = true;
  }

  onPanelCopyClick(evt: ReportLayoutDesignerEvent) {
    this.selectedPage = evt.page;
    const cfg = new ReportPanelSettings(evt.panel);
    cfg.id = uuid();
    this.layout.addPanel(cfg);
  }

  onPanelViewClick(evt: ReportLayoutDesignerEvent) {
    this.selectedPage = evt.page;
    this.viewerPanelSettings = new ReportPanelSettings(evt.panel);
    if(this.viewerPanelSettings.chart.isNumeral()) {
      this.viewerPanelSettings.chart.w = 9;
      this.viewerPanelSettings.chart.h = 9;
    }
    this.viewerPanelPopupVisible = true;
  }

  onBtnAddOkClick(e: any) {
    var cfg = new ReportPanelSettings(this.editorPanelSettings);
    if(cfg.isNewPanel())
    {
      cfg.id = uuid();
      this.layout.addPanel(cfg);
    }
    else
    {
      this.layout.updatePanel(cfg);
    }
    this.editorPopupVisible = false;
    setTimeout(() => {
      if(this.onLoadingStarted)
        this.onLoadingStarted.emit();
      this.runQuery(cfg);
    }, 0);
  }

  onBtnAddCloseClick(e: any) {
    this.editorPopupVisible = false;
  }

  onBtnSaveClick(e: any) {
    const report = new ReportSettings();
    report.uuid = this.settings.uuid;
    report.toolbar = new ReportToolbarSettings(this.toolbar.settings);
    report.pages = this.settings.pages.map((page: ReportPage) => new ReportPage(page));
    this._reportService.saveReport(report).subscribe({
      next: (result: ReportSettings) => {
        console.log("Report saved:", result);
        this.showNotification("Relatório salvo.", "success");
      },
      error: (err) => {
        console.error("Error saving report:", err);
        this.showNotification("Erro ao exportar o relatório.", "error");
      }
    });
  }

  onBtnExportClick(e: any) {
    const report = new ReportSettings(this.settings);
    const filters = this.settings.filters;
    const logo = this.reportHeader?.logo ?? LogoMovias;
    this.isExporting = true;

    // Save and export the report to PDF
    this._reportService.saveReport(report)
    .pipe(
      switchMap(() => { return this._reportService.exportToPDF(report, filters, logo); })
    )
    .subscribe({
      next: (event: HttpEvent<Blob>) => {
        if (event.type === HttpEventType.Response) {
          FileSaver.saveAs(event.body as Blob, `${report.toolbar.title}.pdf`);
          this.isExporting = false;
        }
      },
      error: (err: any) => {
        console.error('Error:', err);
        this.isExporting = false;
        this.showNotification("Erro ao exportar o relatório.", "error");
      }
    });
  }

  onBtnViewPanelCloseClick(e: any) {
    this.viewerPanelPopupVisible = false;
  }

  onBtnViewReportCloseClick(e: any) {
    this.viewerReportPopupVisible = false;
  }

  onBtnBackClick(e: any) {
    if(this.editable) {
      this.confirmSaveAndExitPopupVisible = true;
    } else {
      if(this.onBackClick)
        this.onBackClick.emit();
    }
  }

  onSaveAndExitClick(e: any) {
    this.confirmSaveAndExitPopupVisible = false;
    const report = new ReportSettings(this.settings);
    this._reportService.saveReport(report).subscribe({
      next: (result: ReportSettings) => {
        if(this.onBackClick)
          this.onBackClick.emit();
      },
      error: (err) => {
        console.error("Error saving report:", err);
        this.showNotification("Erro ao exportar o relatório.", "error");
      },
    });
  }

  onExitClick(e: any) {
    this.confirmSaveAndExitPopupVisible = false;
    if(this.onBackClick)
      this.onBackClick.emit();
  }

  onPreviousPageClick(e: any) {
    const idx = Math.max(0, this.currentPageIdx - 1);
    if(idx != this.currentPageIdx) {
      this.currentPageIdx = idx;
      this.selectedPage = this.settings.pages[this.currentPageIdx];
      this.animatePageTransition();
    }
  }

  onNextPageClick(e: any) {
    const idx = Math.min(this.settings.pages.length - 1, this.currentPageIdx + 1);
    if(idx != this.currentPageIdx) {
      this.currentPageIdx = idx;
      this.selectedPage = this.settings.pages[this.currentPageIdx];
      this.animatePageTransition();
    }
  }

  rescaleReportViewerElement() {
    const parent = this.previewReportPopupElement.nativeElement;
    const inner = this.previewReportPageElement.nativeElement;

    const parentWidth = parent.offsetWidth;
    const parentHeight = parent.offsetHeight;

    const innerWidth = inner.offsetWidth;
    const innerHeight = inner.offsetHeight;

    // Calculate the scale factor to fit inner into parent
    const scaleX = parentWidth / innerWidth;
    const scaleY = parentHeight / innerHeight;

    // Choose the smaller scale factor to keep aspect ratio
    const scale = Math.min(scaleX, scaleY);

    // Calculate the new size of the inner element after scaling
    const scaledInnerWidth = innerWidth * scale;
    const scaledInnerHeight = innerHeight * scale;

    // Calculate the offset to center the inner element
    const offsetX = (parentWidth - scaledInnerWidth) / 2;
    const offsetY = (parentHeight - scaledInnerHeight) / 2;

    // Apply both scale and translate together, but this time, apply translate based on scaled dimensions
    inner.style.transform = `translate(${offsetX}px, ${offsetY}px) scale(${scale})`;

    // Ensure scaling happens from the top left corner
    inner.style.transformOrigin = 'top left';
  }

  showNotification(msg: string, type: string) {
    notify({
      message: msg,
      height: 45,
      width: 450,
      minWidth: 150,
      type: type,
      displayTime: 2000,
      animation: {
        show: {
          type: 'fade', duration: 400, from: 0, to: 1,
        },
        hide: { 
          type: 'fade', duration: 250, from: 1, to: 0
        },
      },
    },
    { 
      position: "bottom right", 
      direction: "up-stack" 
    });
  }
}
