<div class="page-header">
  <div class="page-header-content">
    <dx-box direction="row" width="100%" height="100%" crossAlign="center" align="left">
      <dxi-item [visible]="true" [ratio]="3">
        <img [src]="_domSanitizer.bypassSecurityTrustResourceUrl(logo_url)">
      </dxi-item>
      <dxi-item [ratio]="10">
        <dx-box direction="col" width="100%" height="100%" crossAlign="center">
          <dxi-item [ratio]="0" [baseSize]="40">
            <div class="page-title">{{title}}</div>
          </dxi-item>
          <dxi-item [ratio]="0" [baseSize]="10">
            
          </dxi-item>
          <dxi-item [ratio]="0" [baseSize]="10">
            <span>{{ getStartDate() }} a {{ getEndDate() }}</span>
          </dxi-item>
        </dx-box>    
      </dxi-item>  
      <dxi-item [visible]="true" [ratio]="3">
        <dx-box direction="row" width="100%" height="100%" crossAlign="center" align="right">
          <dxi-item [visible]="showToolbar" [ratio]="0" [baseSize]="40" style="margin: 5px 0px 0px 5px;">
            <dx-button icon="plus" (onClick)="onAdd()" hint="Adicionar Painel"></dx-button>
          </dxi-item>
          <dxi-item [visible]="showToolbar" [ratio]="0" [baseSize]="40" style="margin: 5px 0px 0px 5px;">
            <dx-button icon="trash" (onClick)="onRemove()" [disabled]="btnTrashDisabled" hint="Remover Página"></dx-button>
          </dxi-item>
        </dx-box>
      </dxi-item>            
    </dx-box>
  </div>
  <div class="page-horizonal-line"></div>
</div>