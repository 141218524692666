import { Component, Input, OnInit, ViewChild, ViewChildren, SimpleChanges, OnChanges, AfterViewChecked, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { DxFormComponent, DxSortableComponent, DxTagBoxComponent } from 'devextreme-angular';
import DataSource from "devextreme/data/data_source";
import { fromEvent, debounceTime, merge, Subscription, interval, from } from 'rxjs';
import {  ReportPanelModels, ReportValueTypes, ReportPanelSettings, 
          ReportCurrencyTypes, 
          ReportPanelFormat, ReportPanelThreshold, ReportQuery, ReportLineTypes,
          cubes, measures, dimensions, segments, granularities, ReportLineDisplayModes, ReportRadarTypes, TimeDimensionGranularities,
          ReportQueryResults, ReportPanelTextOptionsVariables, ReportFilters, ReportPanelGridFooterTypes
        } from '../report.service';
import { ReportPanelWrapperComponent } from '../report-panel/report-panel-wrapper.component';

import { CubeClientService } from 'src/app/shared/services/cube-client.service';
import { ResultSet } from '@cubejs-client/core';
import { DateTime } from 'luxon';
import "devextreme/ui/html_editor/converters/markdown";

@Component({
  selector: 'app-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss']
})
export class ReportEditorComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {

  @ViewChild(ReportPanelWrapperComponent, { static: true }) 
  previewPanel!: ReportPanelWrapperComponent;

  @ViewChild(DxFormComponent, { static: true }) 
  form!: DxFormComponent;

  editor: ReportEditorForm = new ReportEditorForm();

  @Input()
  settings: ReportPanelSettings = new ReportPanelSettings();

  @Input()
  globalFilters: ReportFilters = new ReportFilters();

  private fieldChangedSub!: Subscription;

  constructor(private cdRef: ChangeDetectorRef, private _cubeClientService: CubeClientService) { 
    
  }

  ngOnInit(): void {

    // Se não é um painel novo, precisamos ajustar os dataSources de acordo com o Cube da métrica selecionada.
    if(!this.settings.isNewPanel())
    {
      // Definimos o valor inicial dos campos do editor
      this.editor.sourceOptions.value = this.settings.query.cube;
      this.editor.modelOptions.value = this.settings.chart.model;
      this.editor.limitOptions.value = this.settings.query.limit;
      this.editor.granularityOptions.value = this.settings.query.timeDimensions[0]?.granularity ?? null;
      if(this.settings.chart.isTimeSeries())
        this.editor.granularityOptions.value = this.settings.query.timeDimensions[0]?.granularity ?? TimeDimensionGranularities.DAY;

      this.runQuery();
    }

    // Subscrevemos aos eventos do editor
    this.fieldChangedSub = this.form.onFieldDataChanged
    .asObservable()
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.runQuery();
    });

    this.editor.modelOptions.onValueChanged = (e: any) => {
      // Ao trocar de modelo, reiniciamos tudo, pois é mais simples do que tentar manter as configurações
      this.settings.query = new ReportQuery();
      this.settings.results = [];

      if(this.settings.chart.isTimeSeries())
      {
        // Time Series só pode ser feito com o Cube Trips e Alerts
        this.editor.sourceOptions.dataSource.filter([["value", "=", "Trips"], "or", ["value", "=", "Alerts"]]);
        this.editor.sourceOptions.dataSource.load();
      }
      else
      {
        // Permitimos todos os cubes para os outros tipos de gráfico
        this.editor.sourceOptions.dataSource.filter(null);
        this.editor.sourceOptions.dataSource.load();
      }

      // Deixamos a usabilidade do editor mais amigável de acordo com o modelo selecionado
      switch(this.settings.chart.model)
      {
        case ReportPanelModels.EMPTY:
          this.editor.measuresOptions.showSelectionControls = false;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 1;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.NUMERAL:
          this.editor.measuresOptions.showSelectionControls = false;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 1;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.PIE:
        case ReportPanelModels.DOUGHNUT:
          this.editor.measuresOptions.showSelectionControls = true;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 10;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.LINEAR_GAUGE:
        case ReportPanelModels.CIRCULAR_GAUGE:
          this.editor.measuresOptions.showSelectionControls = false;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 1;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.BAR_GAUGE:
          this.editor.measuresOptions.showSelectionControls = false;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 10;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.BARS:
          this.editor.measuresOptions.showSelectionControls = false;
          this.editor.dimensionsOptions.showSelectionControls = true;
          this.editor.limitOptions.value = 10;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.RADAR:
          this.editor.measuresOptions.showSelectionControls = true;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 10;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.GRID:
        case ReportPanelModels.TEXT:
          this.editor.measuresOptions.showSelectionControls = true;
          this.editor.dimensionsOptions.showSelectionControls = true;
          this.editor.limitOptions.value = 100;
          this.editor.granularityOptions.value = null;
          this.editor.granularityOptions.showClearButton = true;
          break;
        case ReportPanelModels.TIME_SERIES:
          this.editor.measuresOptions.showSelectionControls = true;
          this.editor.dimensionsOptions.showSelectionControls = false;
          this.editor.limitOptions.value = 30;
          this.editor.granularityOptions.value = TimeDimensionGranularities.DAY;
          this.editor.granularityOptions.showClearButton = false;
          break;
        default:
          console.log("Modelo não implementado:", this.settings.chart.model);
          this.editor.limitOptions.value = 1000;
          break; 
      }

      // Definimos o limite padrão para o tipo de gráfico selecionado
      this.settings.query.limit = this.editor.limitOptions.value;
    };
    this.editor.sourceOptions.onValueChanged = (e: any) => {
      if(e.value == null || e.value.length == 0)
      {
        this.settings.query.cube = "";
        this.form.instance.getEditor("query.measures")?.option("value", []);
        this.form.instance.getEditor("query.measures[0]")?.option("value", null);
        this.form.instance.getEditor("query.dimensions")?.option("value", []);
        this.form.instance.getEditor("query.dimensions[0]")?.option("value", null);
        this.form.instance.getEditor("query.segments")?.option("value", []);
        this.form.instance.getEditor("query.timeDimensions[0].granularity")?.option("value", null);
      }

      // Se trocamos a fonte de dados selecionado, iniciamos uma nova Query
      this.settings.query = new ReportQuery({ cube: this.settings.query.cube, limit: this.settings.query.limit });
      this.settings.results = [];

      // Ajustamos a caixa de seleção com as métricas permitidas para o modelo atual
      if(this.settings.chart.isTimeSeries())
      {
        // Time Series só pode ser feito com o Cube Trips e Alerts
        this.editor.sourceOptions.dataSource.filter([["value", "=", "Trips"], "or", ["value", "=", "Alerts"]]);
        this.editor.sourceOptions.dataSource.load();
      }
      else
      {
        // Permitimos todas as métricas para os outros tipos de gráfico
        this.editor.sourceOptions.dataSource.filter(null);
        this.editor.sourceOptions.dataSource.load();
      }

      // Ajustamos a caixa de seleção com as granularidades permitidas para o modelo atual
      if(this.settings.chart.isTimeSeries())
      {
        // Time Series precisa de uma granularidade
        this.editor.granularityOptions.dataSource.filter([["value", "<>", null], "and", ["value", "<>", TimeDimensionGranularities.SECOND]]);
        this.editor.granularityOptions.dataSource.load();
      }
      else if (this.settings.chart.isGrid())
      {
        // Grid aceita qualquer granularidade
        this.editor.granularityOptions.dataSource.filter(null);
        this.editor.granularityOptions.dataSource.load();
      }
      else
      {
        // Demais graficos não aceitam granularidade
        this.editor.granularityOptions.dataSource.filter([["value", "=", null]]);
        this.editor.granularityOptions.dataSource.load();
      }

      // Definimos as propriedades padrão das timeDimensions para o cube selecionado
      if(this.settings.query.usesTimeDimensions())
      {
        if(this.settings.query.timeDimensions.length == 0)
        {
          this.settings.query.timeDimensions = [
            { dimension: this.settings.query.timeDimensionName, dateRange: [this.globalFilters.startDate.toISODate(), this.globalFilters.endDate.toISODate()] }
          ];
        }
        else
        {
          this.settings.query.timeDimensions[0].dimension = this.settings.query.timeDimensionName;
          this.settings.query.timeDimensions[0].dateRange = [this.globalFilters.startDate.toISODate(), this.globalFilters.endDate.toISODate()];
        }

        if(this.settings.chart.isTimeSeries())
        {
          this.settings.query.timeDimensions[0].granularity = this.settings.query.timeDimensions[0].granularity ?? TimeDimensionGranularities.DAY;
        }
        else
        {
          this.settings.query.timeDimensions[0].granularity = null;
        }
      }
      else
      {
        this.settings.query.timeDimensions = [];
      }

      // Recarregamos as opções de dimensões e segmentos de acordo com o Cube selecionado.
      this.reloadDataSources();
    };
    this.editor.measuresOptions.onValueChanged = (e: any) => {
      // Se limpamos todas as métricas selecionadas, iniciamos uma nova Query
      if(e.value == null || e.value.length == 0)
      {
        this.settings.query.measures = [];
        if(!this.settings.chart.isGrid())
        {
          this.settings.query.dimensions = [];
          this.settings.query.segments = [];
        }
      }

      // Obtemos as configurações padrão da métrica selecionada
      const measure = measures.find((m: any) => m.value == this.settings.query.measures[0]);
      if(measure)
      {
        if(this.settings.chart.isNumeral())
        {
          this.settings.chart.numberOptions.format.valueType = measure.format.valueType;
          this.settings.chart.numberOptions.format.decimalPlaces = measure.format.decimalPlaces;
          this.settings.chart.numberOptions.format.unit = measure.format.unit;
          this.settings.chart.numberOptions.format.currency = measure.format.currency;
          this.settings.chart.numberOptions.format.thresholds = measure.format.thresholds;
        }
        if(this.settings.chart.isLinearGauge())
        {
          this.settings.chart.linearGaugeOptions.unit = measure.format.unit;
        }
        if(this.settings.chart.isCircularGauge())
        {
          this.settings.chart.circularGaugeOptions.unit = measure.format.unit;
        }
      }
    };
    this.editor.dimensionsOptions.onValueChanged = (e: any) => {
      // Se limpamos todas as dimensões selecionadas, resetamos o array de dimensões da Query
      if(e.value == null || e.value.length == 0)
      {
        this.settings.query.dimensions = [];
      }
    };
    this.editor.segmentsOptions.onValueChanged = (e: any) => {
      // Se limpamos todos os segmentos selecionados, resetamos o array de segmentos da Query
      if(e.value == null || e.value.length == 0)
      {
        this.settings.query.segments = [];
      }
    };
  }

  ngAfterViewInit() {
    // Recarregamos as opções de dimensões e segmentos de acordo com o Cube da métrica selecionada.
    this.reloadDataSources();
  }

  ngOnDestroy(): void {
    this.fieldChangedSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onFieldDataChanged(e: any) {
    
  }

  onSortableAdd(e: any) {
    e.toData.splice(e.toIndex, 0, e.itemData);
    // Não precisamos emitir um evento aqui, pois após um Add sempre virá um Remove ou Reorder
  }

  onSortableRemove(e: any) {
    e.fromData.splice(e.fromIndex, 1);
    // Forçamos um trigger de alteração de dados do formulário para forçar um refresh()
    this.form.onFieldDataChanged.emit();
  }

  onSortableReorder(e: any) {
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);
    // Forçamos um trigger de alteração de dados do formulário para forçar um refresh()
    this.form.onFieldDataChanged.emit();
  }

  onSortableDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }
  
  private reloadDataSources() {
    this.editor.measuresOptions.dataSource.filter([["group", "=", this.settings.query.cube], "or", ["group", "=", ""]]);
    this.editor.dimensionsOptions.dataSource.filter([["group", "=", this.settings.query.cube], "or", ["group", "=", ""]]);
    this.editor.segmentsOptions.dataSource.filter([["group", "=", this.settings.query.cube], "or", ["group", "=", ""]]);

    // Recarregamos os dataSources
    this.editor.measuresOptions.dataSource.load();
    this.editor.dimensionsOptions.dataSource.load();
    this.editor.segmentsOptions.dataSource.load();

    // Removemos as métricas, dimensões e segmentos que não existem mais no Cube selecionado
    this.settings.query.measures        = this.settings.query.measures.filter((s: string)     => measures.find(  (m: any) => (m.value == s)  && (m.group == this.settings.query.cube || m.group === "")) != null);
    this.settings.query.dimensions      = this.settings.query.dimensions.filter((s: string)   => dimensions.find((m: any) => (m.value == s)  && (m.group == this.settings.query.cube || m.group === "")) != null);
    this.settings.query.segments        = this.settings.query.segments.filter((s: string)     => segments.find(  (m: any) => (m.value == s)  && (m.group == this.settings.query.cube || m.group === "")) != null);

    setTimeout(() => { 
      this.form.instance.getEditor("query.measures")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.measures")?.option("value", this.settings.query.measures);
      this.form.instance.getEditor("query.measures[0]")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.measures[0]")?.option("value", this.settings.query.measures[0] ?? null);
      this.form.instance.getEditor("query.dimensions")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.dimensions")?.option("value", this.settings.query.dimensions);
      this.form.instance.getEditor("query.dimensions[0]")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.dimensions[0]")?.option("value", this.settings.query.dimensions[0] ?? null);
      this.form.instance.getEditor("query.segments")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.segments")?.option("value", this.settings.query.segments);
      this.form.instance.getEditor("query.limit")?.option("disabled", !this.settings.query.isCubeValid());
      this.form.instance.getEditor("query.timeDimensions[0].granularity")?.option("disabled", !this.settings.query.isCubeValid());
    }, 0);
  }

  getDimensionName(value: string): string {
    const dim = dimensions.find((d: any) => d.value == value);
    return dim?.label ?? value;
  }

  getMeasureName(value: string): string {
    const mes = measures.find((m: any) => m.value == value);
    return mes?.label ?? value;
  }

  private runQuery() {

    this.settings.loading = true;

    if(!this.settings.query.isValid(this.settings.chart.model)) {
      // Damos um pequeno delay, porque temos o debounce de 1 segundo no onFieldDataChanged
      setTimeout(() => {  
        this.settings.results = [];
        this.settings.loading = false; 
      }, 500);
      return;
    }

    // Setting the date range for the query and
    // Making sure we do not have any null granularities
    const timeDimensions = this.settings.query.timeDimensions?.map(td => {
      const dateRange = [this.globalFilters.startDate.toISODate(), this.globalFilters.endDate.toISODate()] as [string, string];
      if (typeof td.granularity === "string") {
        return { dimension: td.dimension, granularity: td.granularity, dateRange: dateRange };
      }
      return { dimension: td.dimension, dateRange: dateRange };
    });

    from(this._cubeClientService.getCubejsApi().load({
      measures:       this.settings.query.measures,
      dimensions:     this.settings.query.dimensions,
      timeDimensions: timeDimensions,
      segments:       this.settings.query.segments,
      filters:        [...this.globalFilters.queryFilters, ...this.settings.query.filters],
      timezone:       Intl.DateTimeFormat().resolvedOptions().timeZone,
      // Limitamos em apenas 5 resultados pois é apenas um preview
      limit:          Math.min(5, this.settings.query.limit),
      order:          this.settings.query.order,
    }))
    .subscribe({
      next: (resultSet: ResultSet) => {
        if(resultSet) {
          this.settings.results = resultSet.tablePivot();
        } else {
          this.settings.results = [];
        }
        this.settings.loading = false;
      },
      error: (err: any) => {
        console.log("Error:", err);
        this.settings.loading = false;
      }
    });
  }
}

/**
 * Atributos do formulário de editor de painéis.
 */
export class ReportEditorForm {

  /**
   * Opções de gráficos.
   */
  modelOptions: any = {
    dataSource: new ArrayStore(
    {
      data: [
        { label: 'Vazio',             value: ReportPanelModels.EMPTY },
        { label: 'Numeral',           value: ReportPanelModels.NUMERAL },
        { label: 'Texto Livre',       value: ReportPanelModels.TEXT },
        { label: 'Pizza',             value: ReportPanelModels.PIE },
        { label: 'Rosca',             value: ReportPanelModels.DOUGHNUT },
        { label: 'Barras',            value: ReportPanelModels.BARS },
        { label: 'Série Temporal',    value: ReportPanelModels.TIME_SERIES },
        { label: 'Medidor Linear',    value: ReportPanelModels.LINEAR_GAUGE },
        { label: 'Medidor Circular',  value: ReportPanelModels.CIRCULAR_GAUGE },
        { label: 'Medidor em Barras', value: ReportPanelModels.BAR_GAUGE },
        { label: 'Tabela',            value: ReportPanelModels.GRID },
        { label: 'Radar/Polar',       value: ReportPanelModels.RADAR },
      ],
      key: 'value'
    }),
    searchEnabled: false, 
    displayExpr: 'label',
    valueExpr: 'value',
    value: ReportPanelModels.EMPTY,
    onValueChanged: null,
    onClosed: null,
  };

  /**
   * Configurações das queries ao CubeJS.
   */
  sourceOptions: any = {
    dataSource:  new DataSource(new ArrayStore({ data: cubes, key: "value" })),
    searchEnabled: false,
    displayExpr: 'label',
    valueExpr: 'value',
    showSelectionControls: false,
    showClearButton: true,
    applyValueMode: "instantly",
    maxDisplayedTags: 1,
    onValueChanged: null,
    onClosed: null,
    value: null,
  };

  measuresOptions: any = {
    dataSource: new DataSource(new ArrayStore({ data: measures, key: "value" })),
    searchEnabled: false,
    displayExpr: 'label',
    valueExpr: 'value',
    showSelectionControls: true,
    showClearButton: true,
    applyValueMode: "instantly",
    maxDisplayedTags: 1,      
    onValueChanged: null,
    onClosed: null,
    disabled: true,
  };

  dimensionsOptions: any ={
    dataSource: new DataSource(new ArrayStore({ data: dimensions, key: "value" })),
    searchEnabled: false,
    displayExpr: 'label',
    valueExpr: 'value',
    showSelectionControls: true,
    showClearButton: true,
    applyValueMode: "instantly",
    maxDisplayedTags: 1,      
    onValueChanged: null,
    onClosed: null,
    disabled: true,
  };

  segmentsOptions: any = {
    dataSource: new DataSource(new ArrayStore({ data: segments, key: "value" })),
    searchEnabled: false,
    displayExpr: 'label',
    valueExpr: 'value',
    showSelectionControls: true,
    showClearButton: true,
    applyValueMode: "instantly",
    maxDisplayedTags: 1,      
    onValueChanged: null,
    onClosed: null,
    disabled: true,
  };

  limitOptions: any = {
    showSpinButtons: true,
    showClearButton: false,
    placeholder: "auto",
    max: 1000,
    min: 1,
    value: 1,
    disabled: true,
  };

  granularityOptions: any = {
    dataSource:  new DataSource(new ArrayStore({ data: granularities, key: "value" })),
    searchEnabled: false,
    displayExpr: 'label',
    valueExpr: 'value',
    showSelectionControls: false,
    showClearButton: true,
    applyValueMode: "instantly",
    maxDisplayedTags: 1,      
    onValueChanged: null,
    onClosed: null,
    value: null,
    disabled: true,
  };

  /**
   * Opções de gráficos numéricos.
   */
  numberOptions: any = {
    valueTypes: {
      dataSource: new ArrayStore(
      {
        data: [
          { label: 'Numeral',       value: ReportValueTypes.NUMBER },
          { label: 'Moeda',         value: ReportValueTypes.CURRENCY },
          { label: 'Duração',       value: ReportValueTypes.DURATION },
          { label: 'Horímetro',     value: ReportValueTypes.HOURMETER },
          { label: '% (0 a 1.0)',   value: ReportValueTypes.PERCENTAGE_0_1 },
          { label: '% (0 a 100)',   value: ReportValueTypes.PERCENTAGE_0_100 },
        ],
        key: 'value'
      }),
      searchEnabled: false,
      displayExpr: 'label',
      valueExpr: 'value',
    },
    decimalPlaces: {
      showSpinButtons: true,
      showClearButton: true,
      placeholder: "auto",
      max: 3,
      min: 0,
      value: null,
    },
    currencyTypes: {
      dataSource: new ArrayStore(
      {
        data: [
          { label: 'Real',           value: ReportCurrencyTypes.BRL },
          { label: 'Dollar (EUA)',   value: ReportCurrencyTypes.USD },
          { label: 'Euro',           value: ReportCurrencyTypes.EUR },
        ],
        key: 'value'
      }),
      searchEnabled: false,
      displayExpr: 'label',
      valueExpr: 'value',
      showClearButton: true,
      placeholder: "auto",
      value: null,
    },
    threshNumber: {
      showSpinButtons: true,
    },
  };

  /**
   * Opções de gráficos em pizza.
   */
  pieOptions: any = {

  };

  /**
   * Opções de gráficos de barras.
   */
  barsOptions: any = {

  };

  /**
   * Opções de gráficos de medidores.
   */
  gaugeOptions: any = {
    colorOptions: {
      showClearButton: true,
      placeholder: "auto",
    },
    intValues: {
      showSpinButtons: true,
      showClearButton: false,
    },
    linearOptions: {
      orientationOptions: {
        dataSource: new ArrayStore(
          {
            data: [
              { label: 'Horizontal',  value: "horizontal" },
              { label: 'Vertical',    value: "vertical" },
            ],
            key: 'value'
          }),
          searchEnabled: false,
          displayExpr: 'label',
          valueExpr: 'value',
          applyValueMode: "instantly",
          onValueChanged: null,
      },
      threshNumber: {
        showSpinButtons: true,
      },
    },
    circularOptions: {
      threshNumber: {
        showSpinButtons: true,
      },
    }
  };

  /**
   * Opções de gráficos de radar.
   */
  radarOptions: any = {
    typesOptions: {
      dataSource: new ArrayStore(
        {
          data: [
            { label: 'Linha',   value: ReportRadarTypes.LINE },
            { label: 'Pontos',  value: ReportRadarTypes.SCATTER, },
            { label: 'Barras',  value: ReportRadarTypes.BARS },
            { label: 'Área',    value: ReportRadarTypes.AREA },
          ],
          key: 'value'
        }),
        searchEnabled: false,
        displayExpr: 'label',
        valueExpr: 'value',
        applyValueMode: "instantly",
        onValueChanged: null,
    },
  };

  /**
   * Opções de tabelas.
   */
  tableOptions: any = {
    showRowNumberOptions: {
      dataSource: new ArrayStore(
        {
          data: [
            { label: 'Sim',  value: true },
            { label: 'Não',  value: false },
          ],
          key: 'value'
        }),
        searchEnabled: false,
        displayExpr: 'label',
        valueExpr: 'value',
        applyValueMode: "instantly",
        onValueChanged: null,
        value: false,
    },
    footerTypeOptions: {
      dataSource: new ArrayStore(
        {
          data: [
            { label: 'Nenhum',  value: ReportPanelGridFooterTypes.NONE },
            { label: 'Total',   value: ReportPanelGridFooterTypes.TOTAL },
            { label: 'Média',   value: ReportPanelGridFooterTypes.AVERAGE },
            { label: 'Mínimo',  value: ReportPanelGridFooterTypes.MINIMUM },
            { label: 'Máximo',  value: ReportPanelGridFooterTypes.MAXIMUM },
          ],
          key: 'value'
        }),
        searchEnabled: false,
        displayExpr: 'label',
        valueExpr: 'value',
        applyValueMode: "instantly",
        onValueChanged: null,
        value: ReportPanelGridFooterTypes.NONE,
    },
  };

  /**
   * Opções de linhas.
   */
  lineOptions: any = {
    typesOptions: {
      dataSource: new ArrayStore(
        {
          data: [
            { label: 'Retas',               value: ReportLineTypes.LINE },
            { label: 'Curvas',              value: ReportLineTypes.SPLINE },
            { label: 'Degraus',             value: ReportLineTypes.STEP_LINE },
            { label: 'Barras',              value: ReportLineTypes.BARS },
            { label: 'Barras Empilhadas',   value: ReportLineTypes.STACKED_BARS },
          ],
          key: 'value'
        }),
        searchEnabled: false,
        displayExpr: 'label',
        valueExpr: 'value',
        applyValueMode: "instantly",
        onValueChanged: null,
    },
    displayModeOptions: {
      dataSource: new ArrayStore(
        {
          data: [
            { label: 'Absolutos',           value: ReportLineDisplayModes.ABSOLUTE },
            { label: 'Relativos (%)',       value: ReportLineDisplayModes.RELATIVE },
          ],
          key: 'value'
        }),
        searchEnabled: false,
        displayExpr: 'label',
        valueExpr: 'value',
        applyValueMode: "instantly",
        onValueChanged: null,
    },
  };

  /**
   * Opções do Editor de Texto
   */
  htmlEditorOptions: any = {
    height: 480,
    valueType: "html",
    toolbar: {
      items: [
        { name: 'header', acceptedValues: [false, 1, 2, 3, 4, 5], options: { inputAttr: { 'aria-label': 'Header' } } }, 
        { name: 'size', acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'], options: { inputAttr: { 'aria-label': 'Font size' } } }, 
        { name: 'font', acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'], options: { inputAttr: { 'aria-label': 'Font family' } } },
        { name: 'separator' },
        { name: 'bold' }, 
        { name: 'italic' }, 
        { name: 'underline' }, 
        { name: 'separator' },
        { name: 'alignLeft' }, 
        { name: 'alignCenter' }, 
        { name: 'alignRight' }, 
        { name: 'alignJustify' }, 
        { name: 'separator' },
        { name: 'color' }, 
        { name: 'background' }, 
        { name: 'separator' }, 
        { name: 'link' }, 
        { name: 'separator' }, 
        { name: 'clear' },
        { name: 'separator' },
        { name: 'orderedList' }, 
        { name: 'bulletList' }, 
        { name: 'separator' },
        { name: 'insertTable' },
        { name: 'deleteTable' },
        { name: 'insertRowAbove' },
        { name: 'insertRowBelow' },
        { name: 'deleteRow' },
        { name: 'insertColumnLeft' },
        { name: 'insertColumnRight' },
        { name: 'deleteColumn' },
        { name: 'separator' },
        { name: 'variable' },
        //{ widget: "dxButton", options: { icon: 'assets/movias-avatar-gray.svg', stylingMode: 'text', onClick: null } },
        { name: 'separator' },
        { name: 'undo' }, 
        { name: 'redo' }, 
      ]
    },
    variables: {
      dataSource: new ArrayStore({
        data: [
          ReportPanelTextOptionsVariables.PANEL_RESULTS,
          ReportPanelTextOptionsVariables.TODAY,
          ReportPanelTextOptionsVariables.NOW,
          ReportPanelTextOptionsVariables.CLIENT_FILTERS,
          ReportPanelTextOptionsVariables.FLEET_FILTERS,
          ReportPanelTextOptionsVariables.PLATE_FILTERS,
          ReportPanelTextOptionsVariables.DRIVER_FILTERS,
        ],
      }),
      escapeChar: [ '{{', '}}' ]
    },
  };
}

