import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { CubeClientModule } from "@cubejs-client/ngx";
import {
          DxToolbarModule, DxSelectBoxModule, DxTemplateModule, DxLookupModule,
          DxBoxModule, DxTagBoxModule, DxButtonModule, DxSpeedDialActionModule,
          DxDropDownButtonModule, DxPopupModule, DxFormModule, DxTabPanelModule,
          DxLoadPanelModule, DxLoadIndicatorModule, DxChartModule, DxPieChartModule,
          DxBarGaugeModule, DxLinearGaugeModule, DxCircularGaugeModule,
          DxPolarChartModule, DxDataGridModule, DxColorBoxModule, DxScrollViewModule,
          DxSortableModule, DxDateRangeBoxModule, DxTabsModule, DxHtmlEditorModule
       } from 'devextreme-angular';
import { KtdGridModule } from '@katoid/angular-grid-layout';

import { ReportDesignerComponent } from './report-designer.component';
import { CubeClientService } from '../shared/services/cube-client.service';

// Set locale according the browser language
import { ReportToolbarComponent } from './report-toolbar/report-toolbar.component';
import { ReportEditorComponent } from './report-editor/report-editor.component';
import { ReportLayoutDesignerComponent } from './report-layout-designer/report-layout-designer.component';
import { ReportPanelWrapperComponent } from './report-panel/report-panel-wrapper.component';
import { ReportPanelBarsComponent } from './report-panel/report-panel-bars/report-panel-bars.component';
import { ReportPanelEmptyComponent } from './report-panel/report-panel-empty/report-panel-empty.component';
import { ReportPanelGaugeBarComponent } from './report-panel/report-panel-gauge-bar/report-panel-gauge-bar.component';
import { ReportPanelGaugeCircularComponent } from './report-panel/report-panel-gauge-circular/report-panel-gauge-circular.component';
import { ReportPanelGaugeLinearComponent } from './report-panel/report-panel-gauge-linear/report-panel-gauge-linear.component';
import { ReportPanelLineComponent } from './report-panel/report-panel-line/report-panel-line.component';
import { ReportPanelNumeralComponent } from './report-panel/report-panel-numeral/report-panel-numeral.component';
import { ReportPanelPieComponent } from './report-panel/report-panel-pie/report-panel-pie.component';
import { ReportPanelRadarComponent } from './report-panel/report-panel-radar/report-panel-radar.component';
import { ReportPanelTextComponent } from './report-panel/report-panel-text/report-panel-text.component';
import { ReportViewerComponent } from './report-viewer.component';
import { ReportPanelGridComponent } from './report-panel/report-panel-grid/report-panel-grid.component';
import { ReportLayoutViewerComponent } from './report-layout-viewer/report-layout-viewer.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ReportFooterComponent } from './report-footer/report-footer.component';

@NgModule({
  declarations: [
    ReportDesignerComponent,
    ReportToolbarComponent,
    ReportEditorComponent,
    ReportLayoutDesignerComponent,
    ReportPanelWrapperComponent,
    ReportPanelBarsComponent,
    ReportPanelEmptyComponent,
    ReportPanelGaugeBarComponent,
    ReportPanelGaugeCircularComponent,
    ReportPanelGaugeLinearComponent,
    ReportPanelLineComponent,
    ReportPanelNumeralComponent,
    ReportPanelPieComponent,
    ReportPanelRadarComponent,
    ReportPanelTextComponent,
    ReportViewerComponent,
    ReportPanelGridComponent,
    ReportLayoutViewerComponent,
    ReportHeaderComponent,
    ReportFooterComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CubeClientModule,
    KtdGridModule,
    HttpClientModule,
    DxChartModule,
    DxPieChartModule,
    DxBarGaugeModule,
    DxLinearGaugeModule,
    DxCircularGaugeModule,
    DxPolarChartModule,
    DxDataGridModule,
    DxToolbarModule,
    DxSelectBoxModule,
    DxTemplateModule,
    DxLookupModule,
    DxBoxModule,
    DxTagBoxModule,
    DxButtonModule,
    DxSpeedDialActionModule,
    DxDropDownButtonModule,
    DxPopupModule,
    DxFormModule,
    DxColorBoxModule,
    DxTabPanelModule,
    DxLoadPanelModule,
    DxLoadIndicatorModule,
    DxScrollViewModule,
    DxSortableModule,
    DxDateRangeBoxModule,
    DxHtmlEditorModule
  ],
  exports: [
    ReportHeaderComponent,
    ReportFooterComponent,
    ReportDesignerComponent,
    ReportViewerComponent
  ],
  providers: [
    CubeClientService,
    HttpClientModule,
    CookieService
  ]
})
export class ReportModule { }
