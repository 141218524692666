<div [id]="'report-designer-' + settings.uuid" class="main content-block dx-card responsive-paddings">

  <dx-load-panel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#report-designer-' + settings.uuid }"
    [container]="'#report-designer-' + settings.uuid"
    [visible]="isExporting"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
  </dx-load-panel>

  <!-- Toolbar -->
  <app-report-toolbar #toolbar 
    [settings]="settings.toolbar"
    [startDate]="settings.filters.startDate"
    [endDate]="settings.filters.endDate"
    [showBtnRefresh]="true"
    [showBtnPageLayout]="editable"
    [showBtnAdd]="editable"
    [showBtnSave]="editable"
    [showBtnExport]="true"
    [showBtnSettings]="editable"
    [disabled]="isLoadingAnyPanel"
    (onBtnBackClick)="onBtnBackClick($event)"
    (onBtnSaveClick)="onBtnSaveClick($event)"
    (onBtnExportClick)="onBtnExportClick($event)"
    (onBtnRefreshClick)="onBtnRefreshClick($event)"
    (onBtnSettingsClick)="onBtnSettingsClick($event)"
    (onFilterChanged)="onFilterChanged($event)"
    (onBtnAddClick)="onBtnAddClick($event)"
    (onBtnToggleLayoutClick)="onBtnToggleLayoutClick($event)"
    (onBtnPreviewClick)="onBtnPreviewClick($event)">
  >        
  </app-report-toolbar>

  <!-- Grid Layout -->
  <div #report class="responsive-paddings">
    <dx-box direction="row">
      <dxi-item [ratio]="1">
        <div style="margin: auto;">
          <dx-button class="btn-page-nav" icon="chevronprev" [disabled]="currentPageIdx == 0" (click)="onPreviousPageClick($event)" [width]="90" [height]="90" [hint]="'Página ' + (currentPageIdx + 1 - 1)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item [visible]="true" [ratio]="10" style="margin: auto;">
        <div class="page" [attr.size]="selectedPage.size" [attr.layout]="selectedPage.layout" [@fadeInOut]="pageFadeState">
          <app-report-header 
            [ownerId]="settings.toolbar.owner.id" 
            [title]="settings.toolbar.title"
            [showToolbar]="editable"
            [startDate]="settings.filters.startDate"
            [endDate]="settings.filters.endDate"
            [btnTrashDisabled]="currentPageIdx == 0"
            [selectedPage]="selectedPage"
            (onBtnAddClick)="onPanelAddClick(selectedPage)" 
            (onBtnRemoveClick)="onPageRemoveClick(selectedPage)">
          </app-report-header>
          <div [id]="'report-page-' + selectedPage.id" class="page-content" [attr.size]="selectedPage.size" [attr.layout]="selectedPage.layout" [style.opacity]="pageContentVisible ? 1 : 0">
            <app-report-layout-designer
              #layout
              [globalFilters]="settings.filters"
              [page]="selectedPage"
              [editable]="editable"
              (onPanelRemoveClick)="onPanelRemoveClick($event)"
              (onPanelEditClick)="onPanelEditClick($event)"
              (onPanelCopyClick)="onPanelCopyClick($event)"
              (onPanelViewClick)="onPanelViewClick($event)">
            </app-report-layout-designer>
          </div>
          <dx-load-panel
            shadingColor="rgba(0,0,0,0.4)"
            [position]="{ of: '#report-page-' + selectedPage.id }"
            [container]="'#report-page-' + selectedPage.id"
            [visible]="selectedPage.loading"
            [showIndicator]="true"
            [showPane]="true"
            [shading]="false"
            [hideOnOutsideClick]="false">
          </dx-load-panel>
          <app-report-footer [uuid]="settings.uuid" [currentPage]="currentPageIdx + 1" [totalPages]="settings.pages.length" [today]="today"></app-report-footer>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1">
        <div style="margin: auto;">
          <dx-button class="btn-page-nav" icon="chevronnext" [disabled]="currentPageIdx == (settings.pages.length - 1)" (click)="onNextPageClick($event)" [width]="90" [height]="90" [hint]="'Página ' + (currentPageIdx + 1 + 1)"></dx-button>
        </div>
      </dxi-item>
    </dx-box>
  </div>

  <!-- Toolbar Pop-up -->
  <dx-popup
  [width]="640"
  [height]="360"
  [showTitle]="true"
  title="Configurações"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [visible]="settingsPopupVisible">
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before">
      <dx-button text="Ok" (click)="onBtnSettingsOkClick($event)"></dx-button>
  </dxi-toolbar-item>
  <div>
    <dx-form
      #formReportSettings
      [formData]="settings.toolbar"
      [readOnly]="false"
      labelMode="outside"
      [showColonAfterLabel]="false"
      labelLocation="left"
      [minColWidth]="300"
      [colCount]="1"
      >
      <dxi-item dataField="title">
        <dxo-label template="Título"></dxo-label>
      </dxi-item>
      <dxi-item dataField="owner.id" editorType="dxSelectBox" [editorOptions]="{ dataSource: clientsDataSource, searchEnabled: true, searchExpr: 'name', displayExpr: 'name', valueExpr: 'id' }">
        <dxo-label template="Cliente"></dxo-label>
      </dxi-item>
      <dxi-item itemType="group" [colCount]="4" caption="Filtros">
        <dxi-item dataField="clientsFilterEnabled">
          <dxo-label template="Clientes"></dxo-label>
        </dxi-item>
        <dxi-item dataField="fleetsFilterEnabled">
          <dxo-label template="Frotas"></dxo-label>
        </dxi-item>
        <dxi-item dataField="vehiclesFilterEnabled">
          <dxo-label template="Veículos"></dxo-label>
        </dxi-item>
        <dxi-item dataField="driversFilterEnabled">
          <dxo-label template="Motoristas"></dxo-label>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </div>
  </dx-popup>

  <!-- Editor Pop-up -->
  <dx-popup
  [width]="1366"
  [height]="768"
  [showTitle]="true"
  [title]="editorPanelSettings.isNewPanel() ? 'Adicionar Painel' : 'Editar Painel'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="editorPopupVisible">
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before">
      <dx-button [icon]="editorPanelSettings.isNewPanel() ? 'add' : 'save'" [text]="editorPanelSettings.isNewPanel() ? 'Adicionar' : 'Salvar'" (click)="onBtnAddOkClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
      <dx-button icon="close" text="Cancelar" (click)="onBtnAddCloseClick($event)"></dx-button>
  </dxi-toolbar-item>
  <app-report-editor #editor *ngIf="editorPopupVisible"
    [settings]="editorPanelSettings"
    [globalFilters]="settings.filters">
  </app-report-editor>
  </dx-popup>

  <!-- Panel Viewer Pop-up -->
  <dx-popup
  [width]="1366"
  [height]="768"
  [showTitle]="true"
  [title]="viewerPanelSettings.title"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="viewerPanelPopupVisible">
  <div id="viewerPanel" class="panel-container">
    <app-report-panel-wrapper #viewPanel
      [title]="viewerPanelSettings.title"
      [chart]="viewerPanelSettings.chart"
      [query]="viewerPanelSettings.query"
      [globalFilters]="settings.filters"
      [results]="viewerPanelSettings.results">
    </app-report-panel-wrapper>
  </div>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#viewerPanel' }"
    [container]="'#viewerPanel'"
    [visible]="viewerPanelSettings.loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="false"
    [hideOnOutsideClick]="false">
  </dx-load-panel>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before" *ngIf="viewerPanelSettings.chart.isExportable()">
    <dx-button icon="export" text="Exportar" (onClick)="viewPanel.export()" [disabled]="!viewerPanelSettings.chart.isExportable()"></dx-button>
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
    <dx-button icon="close" text="Fechar" (onClick)="onBtnViewPanelCloseClick($event)"></dx-button>
  </dxi-toolbar-item>
  </dx-popup>

  <!-- Confirm Remove Panel Pop-up -->
  <dx-popup
  [width]="480"
  [height]="272"
  [showTitle]="true"
  [title]="'Remover Painel'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="confirmRemovePanelPopupVisible">
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before">
    <dx-button text="Cancelar" (click)="onPanelRemoveCancelClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
    <dx-button type="danger" text="Remover" (click)="onPanelRemoveOkClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dx-box direction="row" [height]="100">
    <dxi-item [ratio]="1" style="margin: auto;"><i class="dx-icon-warning dialog-icon dialog-icon-warning"></i></dxi-item>
    <dxi-item [ratio]="3" style="margin: auto;"><div class="dialog-content"><span class="dialog-text">Deseja remover o painel: </span><span class="dialog-important">{{ selectedPanel.title }}?</span></div></dxi-item>
  </dx-box>
  </dx-popup>

  <!-- Confirm Remove Page Pop-up -->
  <dx-popup
  [width]="480"
  [height]="272"
  [showTitle]="true"
  [title]="'Remover Página'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="confirmRemovePagePopupVisible">
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before">
    <dx-button text="Cancelar" (click)="onPageRemoveCancelClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
    <dx-button type="danger" text="Remover" (click)="onPageRemoveOkClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dx-box direction="row" [height]="100">
    <dxi-item [ratio]="1" style="margin: auto;"><i class="dx-icon-warning dialog-icon dialog-icon-warning"></i></dxi-item>
    <dxi-item [ratio]="3" style="margin: auto;"><div class="dialog-content"><span class="dialog-text">Tem certeza de que deseja remover esta página?</span></div></dxi-item>
  </dx-box>
  </dx-popup>

  <!-- Report Preview Pop-up -->
  <dx-popup
  [width]="1366"
  [height]="768"
  [showTitle]="true"
  [title]="'Visualizar Impressão'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="viewerReportPopupVisible"
  (onShown)="onPreviewPanelShown($event)">
  <div #viewerReportPopup style="width: 100%; height: 100%; padding: 0;">
    <div #viewerReportPage class="page" [attr.size]="selectedPage.size" [attr.layout]="selectedPage.layout" style="margin: 0;">
        <dx-scroll-view showScrollbar="onScroll">
          <app-report-viewer *ngIf="viewerReportPopupVisible" [settings]="previewReportSettings" [includeHeaderAndFooter]="true" [runQueries]="false"></app-report-viewer>
        </dx-scroll-view>
      </div>
    </div>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
    <dx-button icon="close" text="Fechar" (click)="onBtnViewReportCloseClick($event)"></dx-button>
  </dxi-toolbar-item>
  </dx-popup>

  <!-- Confirm Exit Pop-up -->
  <dx-popup
  [width]="480"
  [height]="272"
  [showTitle]="true"
  [title]="'Salvar as Alterações?'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="confirmSaveAndExitPopupVisible">
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="before">
    <dx-button type="danger" text="Sair" (click)="onExitClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" locateInMenu="never" location="after">
    <dx-button type="default" text="Salvar" (click)="onSaveAndExitClick($event)"></dx-button>
  </dxi-toolbar-item>
  <dx-box direction="row" [height]="100">
    <dxi-item [ratio]="1" style="margin: auto;"><i class="dx-icon-warning dialog-icon dialog-icon-warning"></i></dxi-item>
    <dxi-item [ratio]="3" style="margin: auto;"><div class="dialog-content"><span class="dialog-text">Salvar as alterações antes de sair?</span></div></dxi-item>
  </dx-box>
  </dx-popup>
</div>