<div #container class="grid-container" [style.width.cm]="page.contentWidth" [style.height.cm]="page.contentHeight">
  <ktd-grid 
    #grid
    [cols]="page.cols"
    [rowHeight]="rowHeight"
    [compactType]="'vertical'"
    [compactOnPropsChange]="true"
    [preventCollision]="true"
    [layout]="gridItems"
    (layoutUpdated)="onLayoutUpdated($event)"
    (dragStarted)="onDragStarted($event)" 
    (dragEnded)="onDragEnded($event)"
    (resizeStarted)="onResizeStarted($event)"
    (resizeEnded)="onResizeEnded($event)">

    <ktd-grid-item *ngFor="let item of page.panels; trackBy:trackById" [id]="item.id" [resizable]="editable" [draggable]="editable" style="border: none; background-color: rgba(0, 0, 0, 0); padding: 5px;">
      <!-- Your grid item content goes here -->
      <dx-box direction="col" class="dx-card panel-container" width="100%" height="100%">
        <dxi-item [ratio]="0" [baseSize]="50" [visible]="!editable">
          <div class="panel-title">{{ item.title }}</div>
        </dxi-item>
        <dxi-item [ratio]="0" [baseSize]="50" [visible]="editable" ktdGridDragHandle>
          <dx-drop-down-button 
            [splitButton]="false" 
            [text]="item.title" 
            stylingMode="contained" 
            [elementAttr]="{ class: 'panel-btngroup', report_panel_id: item.id }" 
            [items]="btnActions"
            displayExpr="label" 
            keyExpr="value" 
            [wrapItemText]="true"
            (onItemClick)="onActionBtnClick({ event: $event, page: page, panel: item })">
          </dx-drop-down-button>
        </dxi-item>
        <dxi-item [ratio]="1">
          <div class="panel-container">
            <app-report-panel-wrapper 
              [title]="item.title"
              [chart]="item.chart"
              [query]="item.query"
              [globalFilters]="globalFilters"
              [results]="item.results"
              [id]="item.id">
            </app-report-panel-wrapper>
          </div>
        </dxi-item>
      </dx-box>
    </ktd-grid-item>
  </ktd-grid>              
</div>

