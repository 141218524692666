import { Component, OnInit } from '@angular/core';
import { ReportSettings, testSettings } from 'src/app/report/report.service';

@Component({
  selector: 'app-page-designer',
  templateUrl: './page-designer.component.html',
  styleUrls: ['./page-designer.component.scss']
})
export class PageDesignerComponent implements OnInit {

  settings: ReportSettings = new ReportSettings(testSettings);

  constructor() { }

  ngOnInit(): void {
  }

}
