import { Component, OnInit, Input, Output, enableProdMode, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { Filter } from '@cubejs-client/core';
import { DxTagBoxComponent } from 'devextreme-angular';
import { ReportPage, ReportPageLayout, ReportToolbarSettings } from '../report.service';
import { Subscription, from, interval, forkJoin, concat, merge, EMPTY } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CubeClientService } from 'src/app/shared/services/cube-client.service';
import { DateTime } from 'luxon';


import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";


/**
 * Filtros aplicados ao report.
 */
export class ReportToolbarFilters {
  /**
   * Filtros.
   */
  filters: any[];

  /**
   * Intervalo de início e fim do report.
   */
  startDate: DateTime = DateTime.utc();
  endDate: DateTime = DateTime.utc();

  /**
   * Construtor.
   */
  constructor() {
    this.filters = [];
  }
}

@Component({
  selector: 'app-report-toolbar',
  templateUrl: './report-toolbar.component.html',
  styleUrls: ['./report-toolbar.component.scss']
})

export class ReportToolbarComponent implements OnInit, OnDestroy {
  clients: string[] = [];
  fleets: string[] = [];
  vehicles: string[] = [];
  drivers: string[] = [];
  childs: string[] = [];

  private isReloading = false;

  noClientTxt: string = "Nenhum cliente encontrado.";
  noFleetTxt: string = "Nenhuma frota encontrada.";
  noVehicleTxt: string = "Nenhum veículo encontrado.";
  noDriverTxt: string = "Nenhum motorista encontrado.";

  @ViewChild('cbClients', { static: true })
  cbClients!: DxTagBoxComponent;
  @ViewChild('cbFleets', { static: true })
  cbFleets!: DxTagBoxComponent;
  @ViewChild('cbVehicles', { static: true })
  cbVehicles!: DxTagBoxComponent;
  @ViewChild('cbDrivers', { static: true })
  cbDrivers!: DxTagBoxComponent;

  @Input()
  settings: ReportToolbarSettings = new ReportToolbarSettings();

  @Input()
  showBtnAdd: boolean = true;
  @Input()
  showBtnSettings: boolean = true;
  @Input()
  showBtnSave: boolean = true;
  @Input()
  showBtnPreview: boolean = true;
  @Input()
  showBtnExport: boolean = true;
  @Input()
  showBtnRefresh: boolean = true;
  @Input()
  showBtnPageLayout: boolean = true;
  @Input()
  disabled: boolean = false;

  @Output()
  onFilterChanged: EventEmitter<ReportToolbarFilters> = new EventEmitter<ReportToolbarFilters>();
  @Output()
  onBtnBackClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnAddClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnSaveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnExportClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnPreviewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnRefreshClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnSettingsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onBtnToggleLayoutClick: EventEmitter<ReportPageLayout> = new EventEmitter<ReportPageLayout>();

  @Input()
  startDate: DateTime = DateTime.utc();

  @Input()
  endDate: DateTime = DateTime.utc();

  startDateJs: Date;
  endDateJs: Date;

  minDate: Date;
  maxDate: Date;

  btnRefreshType: 'primary' | 'danger' = 'primary';

  constructor(private _cubeClientService: CubeClientService) {
    this.startDateJs = this.startDate.toJSDate();
    this.endDateJs = this.endDate.toJSDate();
    this.minDate = DateTime.utc().minus({ years: 1 }).toJSDate();
    this.maxDate = DateTime.utc().toJSDate();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onDateRangeChanged(data: any) {
    this.startDate = DateTime.fromJSDate(data.value[0]);
    this.endDate = DateTime.fromJSDate(data.value[1]);
    if(!this.isReloading)
      this.reload();
  }

  clientValueChanged(data: any) {
    this.clients = data.value;
    if(this.clients.length == 0) {
      this.fleets = [];
      this.vehicles = [];
      this.drivers = [];
    }
    if(!this.isReloading)
      this.reload();
  }

  fleetValueChanged(data: any) {
    this.fleets = data.value;
    if(this.fleets.length == 0) {
      this.vehicles = [];
      this.drivers = [];
    }
    if(!this.isReloading)
      this.reload();
  }

  vehicleValueChanged(data: any) {
    this.vehicles = data.value;
    if(this.vehicles.length == 0) {
      this.drivers = [];
    }
    if(!this.isReloading)
      this.reload();
  }

  driverValueChanged(data: any) {
    this.drivers = data.value;
    if(!this.isReloading)
      this.reload();
  }

  clearFilters(e: any) {
    this.clients = [];
    this.fleets = [];
    this.vehicles = [];
    this.drivers = [];
    this.startDate = DateTime.utc();
    this.endDate = DateTime.utc();
    this.reload();
  }

  onBackClick() {
    if(this.onBtnBackClick)
      this.onBtnBackClick.emit(this);
  }

  onAdd() {
    if(this.onBtnAddClick)
      this.onBtnAddClick.emit(this);
  }

  onSettings() {
    if(this.onBtnSettingsClick)
      this.onBtnSettingsClick.emit(this);
  }

  togglePageLayout() {
    this.settings.defaultPageLayout = (this.settings.defaultPageLayout === ReportPageLayout.PORTRAIT) ? ReportPageLayout.LANDSCAPE : ReportPageLayout.PORTRAIT;
    if(this.onBtnToggleLayoutClick)
      this.onBtnToggleLayoutClick.emit(this.settings.defaultPageLayout);
  }

  onExport() {
    if(this.onBtnExportClick)
      this.onBtnExportClick.emit(this);
  }

  onPreview() {
    if(this.onBtnPreviewClick)
      this.onBtnPreviewClick.emit(this);
  }

  onSave() {
    if(this.onBtnSaveClick)
      this.onBtnSaveClick.emit(this);
  }

  onRefresh() {
    if(this.onBtnRefreshClick)
      this.onBtnRefreshClick.emit(this);
    this.btnRefreshType = 'primary';
  }

  ownerChanged() {

    this.isReloading = true;
    this.disabled = true;

    from(this._cubeClientService.getCubejsApi().load({
      measures: ["ChildClients.count"],
      dimensions: ["ChildClients.id"],
      filters: [{ member: "Clients.id", operator: "equals", values: [this.settings.owner.id.toString()] }],
      segments: [],
    }))
    .subscribe({
      next: (resultSet) => {
        const results = resultSet.tablePivot();
        this.childs = Array.from(new Set(results.map((row) => { return (row["ChildClients.id"]) }))).filter((value) => { return (typeof value === 'string' && value.length > 0) }).map((value) => { return value.toString() });
        this.reload();
      },
      error: (err) => {
        console.log(err);
        this.isReloading = false;
        this.disabled = false;
      }
    });
  }

  /**
   * Opção de reload buscando Clientes, Veículos e Motoristas separadamente.
   */
  reload() {
    let evt = new ReportToolbarFilters();
    this.isReloading = true;
    this.disabled = true;
    
    const childsFilter: Filter = { member: "Clients.id", operator: "equals", values: [...this.childs, this.settings.owner.id.toString()] };
    const clientsFilter: Filter = { member: "Clients.name", operator: "equals", values: this.clients };
    const fleetsFilter: Filter = { member: "Vehicles.fleet", operator: "equals", values: this.fleets };
    const vehiclesFilter: Filter = { member: "Vehicles.plate", operator: "equals", values: this.vehicles };
    const driversFilter: Filter = { member: "Drivers.name", operator: "equals", values: this.drivers };

    const clientsFilters: Filter[] = [];
    const vehiclesFilters: Filter[] = [];
    const driversFilters:  Filter[] = [];

    if(this.settings.owner.id != 0)
    {
      evt.filters.push(childsFilter);
      clientsFilters.push(childsFilter);
      vehiclesFilters.push(childsFilter);
      driversFilters.push(childsFilter);
    }
    if(this.clients.length  > 0 && this.settings.clientsFilterEnabled)
    {
      evt.filters.push(clientsFilter);
      vehiclesFilters.push(clientsFilter);
      driversFilters.push(clientsFilter);
    }
    if(this.fleets.length   > 0 && this.settings.fleetsFilterEnabled)
    {
      evt.filters.push(fleetsFilter);
    }
    if(this.vehicles.length > 0 && this.settings.vehiclesFilterEnabled)
    {
      evt.filters.push(vehiclesFilter);
    }
    if(this.drivers.length > 0 && this.settings.driversFilterEnabled)
    {
      evt.filters.push(driversFilter);
    }

    evt.startDate = this.startDate.toUTC().startOf('day');
    evt.endDate = this.endDate.toUTC().startOf('day');

    merge(
      // Busca os Motoristas
      (this.settings.driversFilterEnabled) ? 
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["Drivers.count"],
        dimensions: ["Drivers.name"],
        filters: driversFilters,
        segments: [],
      })) 
      : EMPTY,

      // Busca Clientes
      (this.settings.clientsFilterEnabled) ?
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["Clients.count"],
        dimensions: ["Clients.name"],
        filters: clientsFilters,
        segments: [],
      })) 
      : EMPTY,

      // Busca Veiculos
      (this.settings.fleetsFilterEnabled || this.settings.vehiclesFilterEnabled) ?
      from(this._cubeClientService.getCubejsApi().load({
        measures: ["Vehicles.count"],
        dimensions: ["Vehicles.plate", "Vehicles.fleet"],
        filters: vehiclesFilters,
        segments: [],
      })) 
      : EMPTY
    )
    .subscribe({
      next: (resultSet) => {
        const results = resultSet.tablePivot();
        if(resultSet.annotation().measures["Clients.count"])
        {
          this.cbClients.instance.option("dataSource", new DataSource({
            store: new ArrayStore({
              key: "name",
              data: Array.from(new Set(results.map((row) => { return (row["Clients.name"]) }))).filter((value) => { return (typeof value === 'string' && value.length > 0) }).map((value) => { return { name: value } }),
            }),
            paginate: true,
            pageSize: 10
          }));
        }
        else if(resultSet.annotation().measures["Vehicles.count"])
        {
          this.cbVehicles.instance.option("dataSource", new DataSource({
            store: new ArrayStore({
              key: "name",
              data: Array.from(new Set(results.map((row) => { return (row["Vehicles.plate"]) }))).filter((value) => { return (typeof value === 'string' && value.length > 0) }).map((value) => { return { name: value } }),
            }),
            paginate: true,
            pageSize: 10
          }));
          this.cbFleets.instance.option("dataSource", new DataSource({
            store: new ArrayStore({
              key: "name",
              data: Array.from(new Set(results.map((row) => { return (row["Vehicles.fleet"]) }))).filter((value) => { return (typeof value === 'string' && value.length > 0) }).map((value) => { return { name: value } }),
            }),
            paginate: true,
            pageSize: 10
          }));
        }
        else if(resultSet.annotation().measures["Drivers.count"])
        {
          this.cbDrivers.instance.option("dataSource", new DataSource({
            store: new ArrayStore({
              key: "name",
              data: Array.from(new Set(results.map((row) => { return (row["Drivers.name"]) }))).filter((value) => { return (typeof value === 'string' && value.length > 0) }).map((value) => { return { name: value } }),
            }),
            paginate: true,
            pageSize: 10
          }));
        }
        else
        {
          // Nada aqui
        }
      },
      error: (err) => {
        console.log(err);
        this.isReloading = false;
        this.disabled = false;
      },
      complete: () => {
        // Sinalizamos que temos pendências para atualizar
        this.btnRefreshType = 'danger';
        this.isReloading = false;
        this.disabled = false;
        this.onFilterChanged.emit(evt);
      }
    });
  }
}
