import { Component, ViewChild } from '@angular/core';
import { DxBarGaugeComponent } from 'devextreme-angular';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults, measures, dimensions } from '../../report.service';

@Component({
  selector: 'app-report-panel-gauge-bar',
  templateUrl: './report-panel-gauge-bar.component.html',
  styleUrls: ['./report-panel-gauge-bar.component.scss']
})
export class ReportPanelGaugeBarComponent extends ReportPanelBaseComponent {

  values: number[] = [];
  legends: string[] = [];

  @ViewChild(DxBarGaugeComponent, { static: true })
  private gauge!: DxBarGaugeComponent;

  constructor() {
    super();
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizeLegend = this.customizeLegend.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }

  customizeLegend(arg: any) {
    return this.legends[arg.item.index];
  }

  customizeTooltip(info: any) {
    const m =  measures.find((measure) => measure.value == this.query.measures[0]);
    const format = m.format.formatValue(info.value);
    const valueText = format.value + " " + format.unit;
    return {
      html: `
        <div>
          <div class='tooltip-header'>${m.label}</div>
          <div class=\'tooltip-body\'>
            <div class=\'series-name\'>
                <div class='top-series-name'>${this.legends[info.index]}</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
            </div>
          </div>
        </div>
      `,
    }
  }

  customizeLabel(arg: any) {
    return `${arg.valueText} ${this.chart.barGaugeOptions.unit || ''}`;
  }

  getMeasureName(m: string) {
    return measures.find((measure) => measure.value == m).label;
  }

  override export(): void {
    this.gauge.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.values = [];
    this.legends = [];
  }

  override redraw(results: ReportQueryResults): void {
    this.values = [];
    this.legends = [];
    for(var i = 0; i < results.length; i++) {
      const row = results[i];
      // Traduzimos dimensões booleanas
      if(this.query.dimensions.length > 0)
      {
        const dimension = this.query.dimensions[0];
        // Filtramos N/As
        if(!row[dimension]) {
          continue;
        }
        const d = dimensions.find((d) => d.value == dimension);
        const format = d.format.formatValue(row[dimension]);
        row[dimension] = format.value + format.unit;
        this.legends.push(row[dimension].toString());
      }
      else
      {
        this.legends.push(this.title);
      }
      if(this.query.measures.length > 0 && row[this.query.measures[0]] != null)
      {
        this.values.push(parseFloat(row[this.query.measures[0]].toString()));
      }
    }
  }

}
