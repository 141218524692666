import { Component, ViewChild } from '@angular/core';
import { ReportPanelBaseComponent } from 'src/app/report/report-panel/report-panel-base.component';
import { DxChartComponent } from 'devextreme-angular';
import { ReportQueryResults, measures, dimensions } from '../../report.service';

@Component({
  selector: 'app-report-panel-bars',
  templateUrl: './report-panel-bars.component.html',
  styleUrls: ['./report-panel-bars.component.scss']
})
export class ReportPanelBarsComponent extends ReportPanelBaseComponent {

  data: any = {};

  @ViewChild(DxChartComponent, { static: true })
  private dxChart!: DxChartComponent;

  constructor() {
    super();
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }

  customizeLabel(arg: any) {

  }

  customizeTooltip(info: any) {
    const m =  measures.find((measure) => measure.value == this.query.measures[0]);
    const format = m.format.formatValue(info.value);
    const valueText = format.value + " " + format.unit;
    const title = (this.query.dimensions.length == 1) ? m.label : info.argumentText;
    return {
      html: `
        <div>
          <div class='tooltip-header'>${title}</div>
          <div class=\'tooltip-body\'>
            <div class=\'series-name\'>
              <div class='top-series-name'>${info.seriesName}</div> </div><div class=\'value-text\'><div class='top-series-value'>${valueText}</div>
            </div>
          </div>
        </div>
      `,
    }
  }

  override export(): void {
    this.dxChart.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.data = {};
  }

  override redraw(results: ReportQueryResults): void {
    // Copiamos os resultados para não alterar o original
    this.data = results;
    for(var i = 0; i < this.data.length; i++) {
      var row = this.data[i];
      // Traduzimos dimensões booleanas
      for(var j = 0; j < this.query.dimensions.length; j++) {
        const dimension = this.query.dimensions[j];
        const d = dimensions.find((d) => d.value == dimension);
        const format = d.format.formatValue(row[dimension]);
        row[dimension] = format.value + format.unit;
      }
      for(var j = 0; j < this.query.measures.length; j++) {
        var measure = this.query.measures[j];
        this.data[i][measure] = parseFloat(row[measure].toString());
      }
    }
    // Filtramos N/As
    if(this.query.dimensions.length > 0)
      this.data = this.data.filter((row: any) => {
      if(this.query.dimensions.length > 1)
        return (row[this.query.dimensions[0]] != "N/A") && (row[this.query.dimensions[1]] != "N/A");
      else
        return (row[this.query.dimensions[0]] != "N/A");
    });
  }

}
