import { Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, EventEmitter, ChangeDetectorRef, Input, Output, enableProdMode, ViewChildren, QueryList, SimpleChanges, OnChanges, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';

import { ReportPanelWrapperComponent } from '../report-panel/report-panel-wrapper.component';
import { ReportPanelSettings, ReportPage, ReportPageLayout, ReportPageSize, ReportFilters } from '../report.service';

@Component({
  selector: 'app-report-layout-viewer',
  templateUrl: './report-layout-viewer.component.html',
  styleUrls: ['./report-layout-viewer.component.scss']
})
export class ReportLayoutViewerComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, OnChanges {

  @ViewChild('gridContainer', { static: true })
  private gridContainer!: ElementRef;

  @ViewChildren('gridItem') 
  private gridItems!: QueryList<ElementRef>;

  @ViewChildren(ReportPanelWrapperComponent)
  panelComponents!: ReportPanelWrapperComponent[];

  @Input()
  page: ReportPage = new ReportPage();

  @Input()
  globalFilters: ReportFilters = new ReportFilters();

  /**
   * angular-grid-layout v1.2.0
   * Gap fixo de 5 pixels em todas as direções.
   */
  gap: number = 5;

  constructor() { 
    
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  ngAfterViewInit(): void {
    // Update layout after the view is checked
    this.layoutToStyle();
  }

  ngAfterViewChecked(): void {   
    // Update container height after the view is checked
    this.adjustContainerHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  private layoutToStyle() {
    if(this.gridItems.length === 0) {
      return;
    }

    const containerWidth = this.page.contentWidth;
    const containerHeight = this.page.contentHeight;
    const gap = 0.1;
    const cols = this.page.cols;
    const rows = this.page.rows;
    const availableWidth = containerWidth - Math.max((gap * (cols - 1)), 0);
    const colWidth = availableWidth / this.page.cols;
    const availableHeight = containerHeight - Math.max((gap * (rows - 1)), 0);
    const rowHeight = availableHeight / this.page.rows;

    // Loop through all grid items and set their position and size
    for(let i = 0; i < this.gridItems.length; i++) {
      const elm = this.gridItems.get(i)?.nativeElement as HTMLElement;
      const panel = this.page.panels[i];
      elm.style.width = `${panel.chart.w * colWidth + gap * Math.max(panel.chart.w - 1, 0)}cm`;
      if(panel.chart.isGrid() || panel.chart.isText()) {
        elm.style.minHeight = `${panel.chart.h * rowHeight + gap * Math.max(panel.chart.h - 1, 0)}cm`;
        elm.style.height = 'fit-content';
      } else {
        elm.style.minHeight = 'auto';
        elm.style.height = `${panel.chart.h * rowHeight + gap * Math.max(panel.chart.h - 1, 0)}cm`;
      }
      elm.style.left = `${panel.chart.x * colWidth + gap * panel.chart.x}cm`;
      elm.style.top = `${panel.chart.y * rowHeight + gap * panel.chart.y}cm`;
    }
  }

  private adjustContainerHeight() {
    let maxHeight = 0;

    // Loop through all grid items and calculate the bottom-most position
    this.gridItems.forEach((gridItem) => {
      const element = gridItem.nativeElement;
      const height = element.offsetHeight;
      const top = element.offsetTop;

      // Calculate the bottom position (top + height)
      const bottomPosition = top + height;
      if (bottomPosition > maxHeight) {
        maxHeight = bottomPosition; // Keep track of the largest bottom position
      }
      
    });

    this.gridContainer.nativeElement.style.height = `${maxHeight}px`;
  }
}
