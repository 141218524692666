<dx-linear-gauge #gauge class="panel-gauge" [value]="total">
  <dxo-scale [startValue]="chart.linearGaugeOptions.min" [endValue]="chart.linearGaugeOptions.max" [tickInterval]="chart.linearGaugeOptions.step">
    <dxo-label [indent]="30" [customizeText]="customizeLabel">
      <dxo-format type="largeNumber"></dxo-format>
    </dxo-label>
  </dxo-scale>
  <dxo-range-container palette="pastel">
      <dxi-range *ngFor="let item of chart.linearGaugeOptions.thresholds;" [startValue]="item.start" [endValue]="item.end" [color]="item.color || ''"></dxi-range>
  </dxo-range-container>
  <dxo-tooltip [enabled]="true"></dxo-tooltip>
  <dxo-geometry [orientation]="chart.linearGaugeOptions.orientation || 'horizontal'"></dxo-geometry>
</dx-linear-gauge>