<dx-scroll-view class="table-container" showScrollbar="onHover" direction="both">
  <table class="styled-table">
    <thead *ngIf="columns.length > 0">
      <tr>
        <th *ngIf="chart.gridOptions.showRowNumber">#</th>
        <th *ngFor="let col of columns">{{ col.caption }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data; let i = index">
        <td *ngIf="chart.gridOptions.showRowNumber">{{ i + 1 }}</td>
        <td *ngFor="let col of columns">
          <div *ngIf="col.format?.valueType !== 'Boolean'">
            <span [style.color]="getColor(col, row)">{{ getText(col, row) }}</span>
          </div>
          <div *ngIf="col.format?.valueType === 'Boolean'">
            <span [style.color]="getColor(col, row)"><i class="dx-icon-isnotblank"></i></span>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="columns.length > 0 && chart.gridOptions.footerType !== 'none'">
      <tr>
        <td *ngIf="chart.gridOptions.showRowNumber"><i [class]="getFooterLabel()"></i></td>
        <td *ngFor="let col of columns">
          <div>
            <span>{{ getFooterText(col) }}</span>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</dx-scroll-view>
