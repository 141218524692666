import { Component, PLATFORM_ID, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, EventEmitter, Input, Output, enableProdMode, ViewChildren, SimpleChanges, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';

import { KtdGridComponent, KtdGridLayout, ktdTrackById, KtdDragStart, KtdDragEnd, KtdGridLayoutItem } from '@katoid/angular-grid-layout';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ReportPanelWrapperComponent } from '../report-panel/report-panel-wrapper.component';
import { ReportPanelSettings, ReportPage, ReportPageLayout, ReportPageSize, ReportFilters, ArrayRemoveItem } from '../report.service';
import { DxDropDownButtonComponent } from 'devextreme-angular';

export class ReportLayoutDesignerEvent {
  page: ReportPage;
  panel: ReportPanelSettings;

  constructor(page: ReportPage, panel: ReportPanelSettings) {
    this.page = page;
    this.panel = panel;
  }
}

@Component({
  selector: 'app-report-layout-designer',
  templateUrl: './report-layout-designer.component.html',
  styleUrls: ['./report-layout-designer.component.scss']
})
export class ReportLayoutDesignerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild(KtdGridComponent, { static: true }) 
  private grid!: KtdGridComponent;

  @ViewChild('container', { static: true })
  private container!: ElementRef;

  @ViewChildren(ReportPanelWrapperComponent)
  panelComponents!: ReportPanelWrapperComponent[];

  @ViewChildren(DxDropDownButtonComponent)
  private btnGroups!: DxDropDownButtonComponent[];

  @Input()
  page: ReportPage = new ReportPage();

  @Input()
  globalFilters: ReportFilters = new ReportFilters();

  @Output()
  onPanelRemoveClick: EventEmitter<ReportLayoutDesignerEvent> = new EventEmitter<ReportLayoutDesignerEvent>();

  @Output()
  onPanelEditClick: EventEmitter<ReportLayoutDesignerEvent> = new EventEmitter<ReportLayoutDesignerEvent>();

  @Output()
  onPanelCopyClick: EventEmitter<ReportLayoutDesignerEvent> = new EventEmitter<ReportLayoutDesignerEvent>();

  @Output()
  onPanelViewClick: EventEmitter<ReportLayoutDesignerEvent> = new EventEmitter<ReportLayoutDesignerEvent>();

  trackById = ktdTrackById;

  private resizeSubscription!: Subscription;

  @Input()
  editable: boolean = true;

  rowHeight: number = 0;

  gridItems: KtdGridLayoutItem[] = [];

  constructor(@Inject(PLATFORM_ID) platformId: string) { 
    
  }

  /**
   * Botões de ações dos painéis.
   */
  btnActions: any[] = [
    {
      label: 'Editar',
      value: "edit",
      icon: 'edit',
    },
    {
      label: 'Remover',
      value: "remove",
      icon: 'trash',
    },
    {
      label: 'Visualizar',
      value: "view",
      icon: 'fullscreen',
    },
    {
      label: 'Clonar',
      value: "copy",
      icon: 'copy',
    }
  ];

  ngOnInit(): void {
    this.gridItems = this.page.panels.map((pnl) => pnl.chart);
    this.resize();
    
    this.resizeSubscription = merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'orientationchange')
    ).pipe(
      debounceTime(50)
    ).subscribe(() => {
      this.resize();
    });
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["page"] && !changes["page"].firstChange) {
      this.gridItems = this.page.panels.map((pnl) => pnl.chart);
      this.resize();
    }
  }

  addPanel(cfg: ReportPanelSettings) {
    // Atualizamos a lista de painéis da página com o novo painel.
    this.page.panels = [...this.page.panels, cfg];
    // Update Grid Layout
    // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
    this.gridItems = this.page.panels.map((pnl) => pnl.chart);
    this.resize();
  }

  removePanel(cfg: ReportPanelSettings) {
    // Atualizamos a lista de painéis da página removendo o painel.
    this.page.panels = ArrayRemoveItem(this.page.panels, (item) => item.id === cfg.id);
    // Update Grid Layout
    // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
    this.gridItems = this.page.panels.map((pnl) => pnl.chart);
    this.resize();
  }

  updatePanel(cfg: ReportPanelSettings) {
    // Atualizamos a lista de painéis da página com o painel atualizado.
    this.page.panels = this.page.panels.map(item => item.id === cfg.id ? cfg : item);
    // Update Grid Layout
    // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
    this.gridItems = this.page.panels.map((pnl) => pnl.chart);
    this.resize();
  }

  onLayoutUpdated(layout: KtdGridLayout) {
    // Important: We need to update the layout of the panels with the new values.
    this.page.panels.forEach((s) => {
      const pnl = s.chart;
      const gridItem = layout.find((item) => item.id === pnl.id);
      if (gridItem) {
        pnl.w = gridItem.w;
        pnl.h = gridItem.h;
        pnl.x = gridItem.x;
        pnl.y = gridItem.y;
      }
    });
    this.resize();
  }

  onDragStarted(event: KtdDragStart) {
    const btn = this.btnGroups?.find((btn) => btn.elementAttr["report_panel_id"] === event.layoutItem.id);
    if(btn) {
      btn.disabled = true;
      btn.hoverStateEnabled = false;
      btn.instance.close();
    }
  }

  onDragEnded(event: KtdDragEnd) {
    const btn = this.btnGroups?.find((btn) => btn.elementAttr["report_panel_id"] === event.layoutItem.id);
    if(btn) {
      btn.hoverStateEnabled = true;
      btn.disabled = false;
    }
  }

  onResizeStarted(event: KtdDragStart) {

  }

  onResizeEnded(event: KtdDragStart) {

  }

  onActionBtnClick(e: any) {
    const evt = new ReportLayoutDesignerEvent(e.page, e.panel);
    switch(e.event.itemData.value) {
      case "edit":
        if(this.onPanelEditClick)
          this.onPanelEditClick.emit(evt);
        break;
      case "remove":
        if(this.onPanelRemoveClick)
          this.onPanelRemoveClick.emit(evt);
        break;
      case "view":
        if(this.onPanelViewClick)
          this.onPanelViewClick.emit(evt);
        break;
      case "copy":
        if(this.onPanelCopyClick)
          this.onPanelCopyClick.emit(evt);
        break;
      default:
        break;
    }
  }

  resize() : void {
    // Calcula o número de colunas com base no layout e tamanho da página.
    const cols = this.page.cols;
    // Garante que nenhum painel vai extrapolar a quantidade de colunas da página.
    this.page.panels = this.page.panels.map((s) => {
      const pnl = s.chart;
      const numberOfRows = this.page.rows;
      pnl.w = pnl.w;
      pnl.h = pnl.h;
      pnl.y = pnl.y;
      pnl.x = pnl.x;
      // Se o painel extrapolar a quantidade de colunas, ele é movido para uma nova linha.
      if((pnl.w + pnl.x) > cols) {
        pnl.w = Math.min(pnl.w, cols);
        pnl.x = 0;
        pnl.y = numberOfRows + 1;
      }
      return s;
    });
    this.gridItems = this.page.panels.map((pnl) => pnl.chart);
    setTimeout(() => {
      // Calcula a altura das linhas em pixels com base no layout e tamanho da página.
      this.rowHeight = ktdGetGridItemRowHeight(this.grid.layout, this.container.nativeElement.clientHeight, 0, this.page.minRows);
    }, 0);
  }
}

/**
 * Adaptado do ktd-angular-grid v2.2.0 para garantir que os itens não extrapolem a altura da página.
 * Função rowHeight = 'fit' com ajustes para um rowHeight inicial de acordo com o layout.
 */
function ktdGetGridItemRowHeight(layout: KtdGridLayout, gridHeight: number, gap: number, minRows: number): number {
  const numberOfRows = layout.reduce((acc, cur) => Math.max(acc, Math.max(cur.y + cur.h, 0)), 0);
  if(numberOfRows <= minRows) {
    return gridHeight / minRows;
  }
  const gapTotalHeight = (numberOfRows - 1) * gap;
  const gridHeightMinusGap = gridHeight - gapTotalHeight;
  return gridHeightMinusGap / numberOfRows;
}