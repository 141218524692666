// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CUBEJS_API_URL: 'https://circular-mora.azure-bras-t-10384.cubecloudapp.dev',
  
  //USER_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpOXRlY2h4IiwiZXhwIjoxNjk3MDIxMDc4fQ.qT_SLRb66LkLFRE7JcFSdwaYXHVZq96VIbw51sGRKNE',
  
  // MAURICIO
  //API_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYXVyaWNpbyIsImV4cCI6MTcwNDAyMTc5M30.-5ZZszpBdqJhSBAbbUghvssoJCGIRsrO1FQpbdSwKbQ',
  
  // I9TECHX
  API_TOKEN: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpOXRlY2h4IiwiZXhwIjoxNzQ0MjkzMjk3fQ.6BJNchZyw12TDqqM2p5gu_k_Vd6Ac3FAlrGCa_jbEQFrtbKRxVAAuv_HWiOEBXhD58ixSBypkniItw_y1sVLFw',

  // RETRAC
  //API_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJSRVRSQUMiLCJleHAiOjE3MDQwNDMzMTF9.p-LXoXPM1UCFt9CSqoWHbTa2P-e9WXI2R4Lx0SVa1Mg',

  MOVIAS_PDF_URL: 'http://localhost:1532/api/v1/reports/pdf',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
