import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageDesignerComponent } from './pages/page-designer/page-designer.component';
import { PageRendererComponent } from './pages/page-renderer/page-renderer.component';
import { ReportModule } from './report/report.module';

const routes: Routes = [
  {
    path: 'designer',
    component: PageDesignerComponent,
  },
  {
    path: 'renderer',
    component: PageRendererComponent,
  },
  {
    path: '**',
    redirectTo: 'designer'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), ReportModule],
  providers: [
    
  ],
  exports: [RouterModule],
  declarations: [
    PageDesignerComponent,
    PageRendererComponent
  ]
})
export class AppRoutingModule { }
