import { Component, ViewChild } from '@angular/core';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { DxChartComponent } from 'devextreme-angular';
import { ReportQueryResults, ReportLineDisplayModes, ReportLineTypes, measures, dimensions } from '../../report.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-report-panel-line',
  templateUrl: './report-panel-line.component.html',
  styleUrls: ['./report-panel-line.component.scss']
})
export class ReportPanelLineComponent extends ReportPanelBaseComponent {

  @ViewChild(DxChartComponent, { static: false })
  private dxChart!: DxChartComponent;

  data: any = {};
  timeDimension: string = '';

  constructor() { 
    super();
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }

  customizeLabel(arg: any) {
    const m =  measures.find((measure) => measure.value == this.query.measures[0]);
    const format = m.format.formatValue(arg.value);
    const valueText = (this.chart.lineOptions.displayMode == ReportLineDisplayModes.RELATIVE) ? `${(arg.value * 100).toFixed(0)}%` : (format.value + " " + format.unit);
    return `${valueText}`; 
  }

  customizeTooltip(info: any) {
    const m =  measures.find((measure) => measure.value == this.query.measures[0]);
    const format = m.format.formatValue(info.value);
    const valueText = (this.chart.lineOptions.displayMode == ReportLineDisplayModes.RELATIVE) ? `${(info.value * 100).toFixed(2)}%` : (format.value + " " + format.unit);
    return {
      html: `
        <div>
          <div class='tooltip-header'>${info.seriesName}</div>
          <div class=\'tooltip-body\'>
            <div class=\'series-name\'>
              <span class='top-series-name'>${DateTime.fromJSDate(info.argument).toLocaleString()}</span> </div><div class=\'value-text\'><span class='top-series-value'>${valueText}</span>
            </div>
          </div>
        </div>
      `,
    }
  }

  getMeasureName(m: string) {
    return measures.find((measure) => measure.value == m)?.label;
  }

  getColor(m: string) {
    return measures.find((measure) => measure.value == m)?.color;
  }

  override export(): void {
    this.dxChart.instance.exportTo(this.title, 'PNG');
  }

  override clear(): void {
    this.data = {};
    this.timeDimension = '';
  }

  override redraw(results: ReportQueryResults): void {
    var total: any[] = [];
    if(this.query.timeDimensions.length == 0)
      return;
    if(!this.query.timeDimensions[0].dimension)
      return;
    if(!this.query.timeDimensions[0].granularity)
      return;
    this.timeDimension = this.query.timeDimensions[0].dimension + '.' + this.query.timeDimensions[0].granularity;
    const result = results.filter((row: any) => ((this.query.dimensions.length == 0) || row[this.query.dimensions[0]] != null));
    // Calculamos o total de cada dia em cada
    result.forEach((row: any) => {
      const date = row[this.timeDimension];
      this.query.measures.forEach((measure) => {
        if(total[date] === undefined)
          total[date] = 0;
        total[date] += parseFloat(row[measure]);
      });
    });
    this.data = result.map((row: any) => {
      // Traduzimos dimensões booleanas
      if(this.query.dimensions.length > 0)
      {
        const dimension = this.query.dimensions[0];
        const d = dimensions.find((d) => d.value == dimension);
        const format = d.format.formatValue(row[dimension]);
        row[dimension] = format.value + format.unit;
      }
      this.query.measures.forEach((measure) => {
        row[measure] = parseFloat(row[measure]);
        if(this.chart.lineOptions.displayMode == ReportLineDisplayModes.RELATIVE)
          row[measure] /= total[row[this.timeDimension]];
      });
      row[this.timeDimension] = DateTime.fromISO(row[this.timeDimension]).toJSDate();
      return row;
    });
  }
}
