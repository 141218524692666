<dx-circular-gauge #gauge class="panel-gauge" [value]="total" [centerTemplate]="(chart.w >= 3 && chart.h >= 2) ? 'centerTemplate' : undefined">
  <dxo-scale [startValue]="chart.circularGaugeOptions.min" [endValue]="chart.circularGaugeOptions.max" [tickInterval]="chart.circularGaugeOptions.step">
    <dxo-label [customizeText]="customizeLabel">
      <dxo-format type="largeNumber"></dxo-format>
    </dxo-label>
  </dxo-scale>
  <dxo-range-container palette="pastel">
    <dxi-range *ngFor="let item of chart.circularGaugeOptions.thresholds;" [startValue]="item.start" [endValue]="item.end" [color]="item.color || ''"></dxi-range>
  </dxo-range-container>
  <dxo-geometry [startAngle]=180 [endAngle]=0></dxo-geometry>
  <dxo-tooltip [enabled]="true"></dxo-tooltip>
  <svg *dxTemplate="let gauge of 'centerTemplate'">
    <rect x="0" y="0" width="200" height="200" fill="transparent"></rect>
    <g transform="translate(50 30)">
     <rect x="0" y="0" width="100" height="35" rx="8" fill="#fff"></rect>
      <text text-anchor="middle" y="25" x="50" fill="#000" font-size="20">
        <tspan x="50">{{ formatNumber(gauge.value()) }}</tspan>
      </text>
    </g>    
  </svg>
</dx-circular-gauge>