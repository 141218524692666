import { Component, HostBinding, Inject } from '@angular/core';
import { ScreenService } from './shared/services';
import { locale, loadMessages } from "devextreme/localization";
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

const ptMessages = require("devextreme/localization/messages/pt.json");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(private screen: ScreenService, @Inject(PLATFORM_ID) private platformId: Object) { 
    if(isPlatformBrowser(this.platformId)) {
      loadMessages(ptMessages);
      locale(navigator.language);
    }
  }

}
