import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService, ReportSettings, ReportFilters } from 'src/app/report/report.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-page-renderer',
  templateUrl: './page-renderer.component.html',
  styleUrls: ['./page-renderer.component.scss']
})
export class PageRendererComponent {

  report: ReportSettings = new ReportSettings();
  
  constructor(router: Router, route: ActivatedRoute, private service: ReportService, cookieService: CookieService) { 
    // TODO: Obter o report do localStorage quando o IronPDF disponibilizar essa API
    // Infelizmente, cookies não são suficientes para armazenar o report, pois o JSON do report pode exceder o limite de 4KB
    // Por enquanto, pegamos o UUID e os filtros da URL
    router.routerState.root.queryParams.subscribe(params => {
      const reportUuid = params['uuid']?.toString() ?? "";
      this.service.getReport(reportUuid).subscribe(report => {
        this.report = report;
        this.report.filters = ReportFilters.fromQueryParams(params);
        console.log(this.report);
      });
    });
  }

}
