import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults, ReportPanelTextOptionsVariables } from '../../report.service';
import { DateTime } from 'luxon';
import * as showdown from 'showdown';

@Component({
  selector: 'app-report-panel-text',
  templateUrl: './report-panel-text.component.html',
  styleUrl: './report-panel-text.component.scss'
})
export class ReportPanelTextComponent extends ReportPanelBaseComponent {
  
  html: SafeHtml = '';

  private converter: showdown.Converter;

  constructor(private sanitizer: DomSanitizer) {
    super();
    this.converter = new showdown.Converter({ tables: true, openLinksInNewWindow: true, extensions: [] });
  }

  override clear(): void {
    this.html = '';
  }

  override redraw(results: ReportQueryResults): void {

    let panelContent = this.chart.textOptions.text.repeat(1);

    Object.values(ReportPanelTextOptionsVariables).forEach((key) => {

      let value = '';

      switch(key) {
        case ReportPanelTextOptionsVariables.TODAY:
          value = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
          break;
        case ReportPanelTextOptionsVariables.NOW:
          value = DateTime.now().toLocaleString(DateTime.DATETIME_SHORT);
          break;
        case ReportPanelTextOptionsVariables.PANEL_RESULTS:
          // TODO
          value = '';
          break;
        case ReportPanelTextOptionsVariables.CLIENT_FILTERS:
          value = this.globalFilters.queryFilters.find(f => f.member === 'Clients.name')?.values.join(', ') || '';
          break;
        case ReportPanelTextOptionsVariables.FLEET_FILTERS:
          value = this.globalFilters.queryFilters.find(f => f.member === 'Vehicles.fleet')?.values.join(', ') || '';
          break;
        case ReportPanelTextOptionsVariables.PLATE_FILTERS:
          value = this.globalFilters.queryFilters.find(f => f.member === 'Vehicles.plate')?.values.join(', ') || '';
          break;
        case ReportPanelTextOptionsVariables.DRIVER_FILTERS:
          value = this.globalFilters.queryFilters.find(f => f.member === 'Drivers.name')?.values.join(', ') || '';
          break;
        default:
          value = '';
          break;
      }

      const regex = new RegExp(`{{${key}}}`, 'g');
      panelContent = panelContent.replace(regex, value);
    });

    this.html = this.sanitizer.bypassSecurityTrustHtml(this.chart.textOptions.mode === 'html' ? panelContent : this.converter.makeHtml(panelContent));
  }
}
