<dx-box direction="row" width="100%" [height]="50" crossAlign="center" class="report-toolbar">   
  <dxi-item [visible]="true" [ratio]="0" [baseSize]="40" style="margin: 5px 5px 0px 5px;">
    <dx-button icon="chevronprev" (click)="onBackClick()" hint="Voltar"></dx-button>
  </dxi-item>
  <dxi-item [visible]="showBtnAdd" [ratio]="0" [baseSize]="40" style="margin: 5px 5px 0px 5px;">
      <dx-button icon="copy" (onClick)="onAdd()" [disabled]="disabled" hint="Nova Página"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="250" [visible]="true" style="margin: 0px 5px 0px 5px; padding: 0px 0px 0px 0px;">
    <dx-date-range-box 
      [height]="60"
      style="padding: 5px 0px 0px 0px;"
      [startDate]="startDateJs"
      [endDate]="endDateJs"
      [min]="minDate"
      [max]="maxDate"
      [disabled]="disabled"
      (onValueChanged)="onDateRangeChanged($event)">
    </dx-date-range-box>
  </dxi-item>    
  <dxi-item [ratio]="32">
    <dx-box direction="row" width="100%" style="margin-top: 12px;"> 
      <dxi-item class="dx-fieldset" [style.minWidth.px]="200" [ratio]="1" [visible]="settings.clientsFilterEnabled">
        <dx-tag-box #cbClients
          [visible]="settings.clientsFilterEnabled"
          [value]="clients" 
          searchExpr="name"
          displayExpr="name"
          valueExpr="name"
          [searchEnabled]="true" 
          [showSelectionControls]="true" 
          [showClearButton]="true"
          [multiline]="false"
          applyValueMode="useButtons" 
          (onValueChanged)="clientValueChanged($event)"
          label="Clientes" 
          labelMode="floating"
          selectAllMode="allPages"
          [noDataText]="noClientTxt"
          placeholder="Clientes"
          [maxDisplayedTags]="1"
          [showMultiTagOnly]="true"
          [wrapItemText]="true"
          [maxFilterQueryLength]="1000000"
          [disabled]="disabled">
        </dx-tag-box>
      </dxi-item>
      <dxi-item class="dx-fieldset" [style.minWidth.px]="200" [ratio]="1" [visible]="settings.fleetsFilterEnabled">
        <dx-tag-box #cbFleets
          [visible]="settings.fleetsFilterEnabled"
          [value]="fleets" 
          searchExpr="name"
          displayExpr="name"
          valueExpr="name"
          [searchEnabled]="true" 
          [showSelectionControls]="true" 
          [showClearButton]="true"
          [multiline]="false"
          applyValueMode="useButtons" 
          (onValueChanged)="fleetValueChanged($event)"
          label="Frotas" 
          labelMode="floating"
          selectAllMode="allPages"
          [noDataText]="noFleetTxt"
          placeholder="Frotas"
          [maxDisplayedTags]="1"
          [showMultiTagOnly]="true"
          [wrapItemText]="true"
          [maxFilterQueryLength]="1000000"
          [disabled]="disabled">
        </dx-tag-box>
      </dxi-item>
      <dxi-item class="dx-fieldset" [style.minWidth.px]="200" [ratio]="1" [visible]="settings.vehiclesFilterEnabled">
        <dx-tag-box #cbVehicles
          [visible]="settings.vehiclesFilterEnabled"
          [value]="vehicles" 
          searchExpr="name"
          displayExpr="name"
          valueExpr="name"
          [searchEnabled]="true" 
          [showSelectionControls]="true" 
          [showClearButton]="true"
          [multiline]="false"
          applyValueMode="useButtons" 
          (onValueChanged)="vehicleValueChanged($event)"
          label="Veículos" 
          labelMode="floating"
          selectAllMode="allPages"
          [noDataText]="noVehicleTxt"
          placeholder="Veículos"
          [maxDisplayedTags]="1"
          [showMultiTagOnly]="true"
          [wrapItemText]="true"
          [maxFilterQueryLength]="1000000"
          [disabled]="disabled">
        </dx-tag-box>
      </dxi-item>
      <dxi-item class="dx-fieldset" [style.minWidth.px]="200" [ratio]="1" [visible]="settings.driversFilterEnabled">
        <dx-tag-box #cbDrivers
          [visible]="settings.driversFilterEnabled"
          [value]="drivers" 
          searchExpr="name"
          displayExpr="name"
          valueExpr="name"
          [searchEnabled]="true" 
          [showSelectionControls]="true" 
          [showClearButton]="true"
          [multiline]="false"
          applyValueMode="useButtons" 
          (onValueChanged)="driverValueChanged($event)"
          label="Motoristas" 
          labelMode="floating"
          selectAllMode="allPages"
          [noDataText]="noDriverTxt"
          placeholder="Motoristas"
          [maxDisplayedTags]="1"
          [showMultiTagOnly]="true"
          [wrapItemText]="true"
          [maxFilterQueryLength]="1000000"
          [disabled]="disabled">
        </dx-tag-box>
      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnPageLayout" style="margin: 5px 5px 0px 5px;">
    <dx-button icon="preferences" (onClick)="onSettings()" [disabled]="disabled" hint="Configurações"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnSettings" style="margin: 5px 5px 0px 5px;">
    <dx-button *ngIf="settings.defaultPageLayout === 'portrait'"  [icon]="'assets/tablet-portrait-sharp.svg'"  (onClick)="togglePageLayout()" [disabled]="disabled" hint="Retrato"></dx-button>
    <dx-button *ngIf="settings.defaultPageLayout === 'landscape'" [icon]="'assets/tablet-landscape-sharp.svg'" (onClick)="togglePageLayout()" [disabled]="disabled" hint="Paisagem"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnPreview" style="margin: 5px 5px 0px 5px;">
    <dx-button icon="eyeopen" (onClick)="onPreview()" [disabled]="disabled" hint="Visualizar Impressão"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnExport" style="margin: 5px 5px 0px 5px;">
    <dx-button icon="exportpdf" (onClick)="onExport()" [disabled]="disabled" hint="Exportar"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnSave" style="margin: 5px 5px 0px 5px;">
    <dx-button icon="save" (onClick)="onSave()" [disabled]="disabled" hint="Salvar"></dx-button>
  </dxi-item>
  <dxi-item [ratio]="0" [baseSize]="40" [visible]="showBtnRefresh" style="margin: 5px 0px 0px 5px;">
    <dx-button icon="refresh" (onClick)="onRefresh()" [disabled]="disabled" [type]="btnRefreshType" hint="Gerar Relatório"></dx-button>
  </dxi-item>
</dx-box>