<div #gridContainer class="grid-container" [style.width.cm]="page.contentWidth" [style.minHeight.cm]="page.contentHeight">
  <div *ngFor="let item of page.panels;"
    #gridItem
    class="grid-item" 
    [id]="item.id">
    <dx-box direction="col" class="dx-card panel-container">
      <dxi-item [ratio]="0" [baseSize]="50" [visible]="true">
        <div class="panel-title">{{ item.title }}</div>
      </dxi-item>
      <dxi-item [ratio]="1" baseSize="auto">
        <div class="panel-wrapper" [style.height]="'100%'">
          <app-report-panel-wrapper 
            [title]="item.title"
            [chart]="item.chart"
            [query]="item.query"
            [globalFilters]="globalFilters"
            [results]="item.results"
            [id]="item.id">
          </app-report-panel-wrapper>
        </div>
      </dxi-item>
    </dx-box>      
  </div>
</div>

