import { Component, OnInit } from '@angular/core';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { CubeClientService } from 'src/app/shared/services/cube-client.service';

@Component({
  selector: 'app-report-panel-empty',
  templateUrl: './report-panel-empty.component.html',
  styleUrls: ['./report-panel-empty.component.scss']
})
export class ReportPanelEmptyComponent extends ReportPanelBaseComponent {

  constructor(_cubeClientService: CubeClientService) { 
    super();
  }

}
