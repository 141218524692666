import { Component, OnInit, AfterContentInit, AfterViewInit, Input, Output, EventEmitter, QueryList, ViewChildren, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ReportSettings, ReportPage, ReportPanelSettings } from './report.service';
import { CubeClientService } from 'src/app/shared/services/cube-client.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, from, concatMap, forkJoin, catchError, of, tap, defer, delay, iif, map, EMPTY } from 'rxjs';
import { DateTime } from 'luxon';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ReportLayoutViewerComponent } from './report-layout-viewer/report-layout-viewer.component';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit, AfterContentInit, AfterViewInit, OnChanges {

  @Input()
  settings: ReportSettings = new ReportSettings();

  @Output()
  onLoadingStarted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onLoadingFinished: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(ReportLayoutViewerComponent, { read: ElementRef })
  layoutComponents!: QueryList<ElementRef>;

  @Input()
  includeHeaderAndFooter: boolean = true;

  @Input()
  prepareForPdf: boolean = false;

  @Input()
  runQueries: boolean = true;

  private tstructs: ReportPanelTableStructure[] = [];

  get isLoadingAnyPanel(): boolean {
    return this.settings?.pages.some((page: ReportPage) => page.panels.some((panel: ReportPanelSettings) => panel.loading)) ?? false;
  }

  constructor(private _cubeClientService: CubeClientService, public _domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document) { 
    
  }

  private renderReport(): void {
    of(null).pipe(
      concatMap(() => {
        return this.emitLoadStarted();
      }),
      concatMap(() => {
        return this.loadOwner();
      }),
      concatMap(() => {
        return this.loadClientsFilter();
      }),
      concatMap(() => {
        return this.runReportQueries(this.settings.pages.flatMap((page: ReportPage) => page.panels));
      }),
      delay(2000),  // Delay for 2 seconds to allow the tables to render
      concatMap(() => {
        return this.adjustTablesHeight();
      }),
      concatMap(() => {
        return this.emitLoadFinished();
      }),
      concatMap(() => {
        return this.notifyIronPdf();
      }),
    )
    .subscribe({
      next: () => console.log('All operations completed'),
      error: (err) => console.error('Error during the pipeline:', err),
    });
  }

  ngOnInit() {
    
  }

  ngAfterContentInit() {
    
  }

  ngAfterViewInit() {
    this.layoutComponents.forEach((layoutComponent) => {
      // Mapeamos todas as tabelas para poder ajustar seus tamanhos caso elas ocupem mais de uma página
      const tables = layoutComponent.nativeElement.querySelectorAll('.table-container');
      tables.forEach((table: HTMLElement) => {
        const page = table.closest('.page-content') as HTMLElement;
        const gridItem = table.closest('.grid-item') as HTMLElement;

        const tstruct = new ReportPanelTableStructure();
        tstruct.pageId = page.id;
        tstruct.gridItemId = gridItem.id;
        tstruct.initialPageHeight = page.offsetHeight;
        tstruct.initialHeadHeight = table.querySelector('thead')?.offsetHeight ?? 0;
        tstruct.initialFootHeight = table.querySelector('tfoot')?.offsetHeight ?? 0;
        tstruct.initialBodyHeight = table.querySelector('tbody')?.offsetHeight ?? 0;
        
        this.tstructs.push(tstruct);
      });
    });
    // We load and render the report after the view is initialized
    this.renderReport();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['settings'] && !changes['settings'].firstChange) {
      console.log('Report settings changed:', changes['settings']);
      this.renderReport();
    }
  }

  private loadOwner() : Observable<void> {
    // Carregamos o owner do relatório
    return iif(
      () => this.settings.toolbar.owner.id === 0 && this.runQueries,
      defer(() => {
        console.log('Loading owner...');
        return from(this._cubeClientService.getCubejsApi().load({
          measures: ["Myself.count"],
          dimensions: ["Clients.id", "Clients.name"],
          filters: [],
          segments: [],
        }));
      })
      .pipe(
        tap(resultSet => {
          const results = resultSet.tablePivot();
          if (results.length > 0) {
            this.settings.toolbar.owner.id = parseInt(results[0]["Clients.id"].toString());
            this.settings.toolbar.owner.name = results[0]["Clients.name"].toString();
          }
        }),
        catchError(err => {
          console.log("Error loading owner:", err);
          throw err;
        }),
        map(() => void 0)
      ),
      of(void 0)
    );
  }

  private loadClientsFilter(): Observable<void> {
    // Create an observable for loading clients
    return iif(
      () => this.runQueries,
      defer(() => {
        console.log('Loading clients filter...');
        return from(this._cubeClientService.getCubejsApi().load({
          measures: ["ChildClients.count"],
          dimensions: ["ChildClients.id"],
          filters: [{ dimension: "Clients.id", operator: "equals", values: [this.settings.toolbar.owner.id.toString()] }],
          segments: [],
        }));
      })
      .pipe(
        tap(resultSet => {
          const results = resultSet.tablePivot();
          const childClients = Array.from(new Set(results.map(row => row["ChildClients.id"])))
            .filter(value => typeof value === 'string' && value.length > 0)
            .map(value => value.toString());
    
          // Update the filters in settings
          this.settings.filters.queryFilters.push({
            dimension: "Clients.id",
            operator: "equals",
            values: [this.settings.toolbar.owner.id.toString(), ...childClients],
          });
        }),
        catchError(err => {
          console.log("Error loading clients filter:", err);
          throw err;
        }),
        map(() => void 0)
      ),
      of(void 0)
    );
  }

  private runReportQueries(panels: ReportPanelSettings[]): Observable<void> { 
    const observables = panels.map((pnl) => {
      return defer(() => {
        
        pnl.loading = true;
        pnl.results = [];

        if(!pnl.query.isValid(pnl.chart.model)) {
          pnl.loading = false;
          return of(null);
        }
  
        // Setting the date range for the query and making sure we do not have any null granularities
        const timeDimensions = pnl.query.timeDimensions?.map(td => {
          const dateRange = [this.settings.filters.startDate.toISODate(), this.settings.filters.endDate.toISODate()] as [string, string];
          if (typeof td.granularity === "string") {
            return { dimension: td.dimension, granularity: td.granularity, dateRange: dateRange };
          }
          return { dimension: td.dimension, dateRange: dateRange };
        });

        return from(this._cubeClientService.getCubejsApi().load({
          measures:       pnl.query.measures,
          dimensions:     pnl.query.dimensions,
          timeDimensions: timeDimensions,
          segments:       pnl.query.segments,
          filters:        [...this.settings.filters.queryFilters, ...pnl.query.filters],
          timezone:       Intl.DateTimeFormat().resolvedOptions().timeZone,
          limit:          pnl.query.limit,
          order:          pnl.query.order,
        }));
      })
      .pipe(
        tap((resultSet: any) => {
          if(resultSet) {
            pnl.results = resultSet.tablePivot();
          } else {
            pnl.results = [];
          }
          pnl.loading = false;
        }),
        catchError((err) => {
          pnl.results = [];
          pnl.loading = false;
          console.log("Error:", err);
          return of(null);
        })
      );
    });

    // Use forkJoin to combine all observables and return an Observable<void>
    return iif(
      () => observables.length > 0 && this.runQueries,
      defer(() => {
        console.log("Running queries...");
        return forkJoin(observables).pipe(
          tap(() => {
            console.log("All panels loaded");
          }),
          map(() => void 0)
        );
      }),
      of(void 0)
    );
  }

  private adjustTablesHeight(): Observable<void> {
    const window: any = this.document.defaultView as any;
    return iif(
      () => window.ironpdf || this.prepareForPdf,
      new Observable<void>((observer) => {

        // Obtemos as alturas finais das tabelas
        this.layoutComponents.forEach((layoutComponent) => {
          const tables = layoutComponent.nativeElement.querySelectorAll('.table-container');
          tables.forEach((table: HTMLElement) => { // 55 -> 75?
            const page = table.closest('.page-content') as HTMLElement;
            const gridItem = table.closest('.grid-item') as HTMLElement;
            
            const tstruct = this.tstructs.find((tstruct) => tstruct.pageId === page.id && tstruct.gridItemId === gridItem.id);
            if(tstruct) {
              tstruct.finalPageHeight = page.offsetHeight;
              tstruct.finalHeadHeight = table.querySelector('thead')?.offsetHeight ?? 0;
              tstruct.finalFootHeight = table.querySelector('tfoot')?.offsetHeight ?? 0;
              tstruct.finalBodyHeight = table.querySelector('tbody')?.offsetHeight ?? 0;
            }
          });
        });

        console.log('Adjusting table heights: ', this.tstructs);
        this.tstructs.forEach((tstruct) => {
          const page = this.document.getElementById(tstruct.pageId) as HTMLElement;
          const gridItem = page.querySelector(".grid-item") as HTMLElement;
          const table = gridItem.querySelector('.table-container') as HTMLElement;
          // Calculamos quantas páginas a tabela ocupa
          const pageSpan = Math.ceil(tstruct.finalBodyHeight / tstruct.initialPageHeight);
          // Header e footer são copiados para cada página, então o temos que aumentar o tamanho do componente para sair correto no pdf
          const addedHeight = (tstruct.finalHeadHeight + tstruct.finalFootHeight) * (pageSpan + 1);
          table.style.height = (tstruct.finalBodyHeight + addedHeight) + 'px';
        });
    
        observer.next();
        observer.complete();
      }),
      of(void 0)
    );
  }

  private notifyIronPdf(): Observable<void> {
    const window: any = this.document.defaultView as any;
    return iif(
      () => window.ironpdf,
      new Observable<void>((observer) => {
        console.log("Notifying IronPdf...");
        window.ironpdf.notifyRender();
    
        observer.next();
        observer.complete();
      }),
      of(void 0)
    );    
  }

  private emitLoadStarted(): Observable<void> {
    return of(() => {
      if (this.onLoadingStarted) {
        this.onLoadingStarted.emit();
      }
    })
    .pipe(map(() => void 0));
  }

  private emitLoadFinished(): Observable<void> {
    return of(() => {
      if (this.onLoadingFinished) {
        this.onLoadingFinished.emit();
      }
    })
    .pipe(map(() => void 0));
  }

}

/**
 * Estrutura inicial e final de uma tabela de relatório para ajustes de margens na geração de PDFs.
 */
class ReportPanelTableStructure {
  pageId: string;
  gridItemId: string;

  initialPageHeight: number;
  initialBodyHeight: number;
  initialHeadHeight: number;
  initialFootHeight: number;

  finalPageHeight: number;
  finalBodyHeight: number;
  finalHeadHeight: number;
  finalFootHeight: number;

  constructor() {
    this.pageId = '';
    this.gridItemId = '';
    this.initialPageHeight = 0;
    this.initialBodyHeight = 0;
    this.initialHeadHeight = 0;
    this.initialFootHeight = 0;
    this.finalPageHeight = 0;
    this.finalBodyHeight = 0;
    this.finalHeadHeight = 0;
    this.finalFootHeight = 0;
  }
}