import { Component } from '@angular/core';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults, ReportPanelThreshold, ReportFormattedText } from '../../report.service';

@Component({
  selector: 'app-report-panel-numeral',
  templateUrl: './report-panel-numeral.component.html',
  styleUrls: ['./report-panel-numeral.component.scss']
})
export class ReportPanelNumeralComponent extends ReportPanelBaseComponent {

  color: string = "black";
  formattedText: ReportFormattedText;
  get valueSize(): number {
    return Math.min(((this.chart.w > this.chart.h ? this.chart.h : this.chart.w) * 3), 18);
  }
  get unitSize(): number {
    return (this.chart.w > this.chart.h ? this.chart.h : this.chart.w) * 1;
  }
  get unitMarginTop(): number {
    return (this.chart.w > this.chart.h ? this.chart.h : this.chart.w) * 1.5;
  }

  constructor() {
    super();
    this.formattedText = new ReportFormattedText("", "");
  }

  override clear(): void {
    this.formattedText = new ReportFormattedText("", "");
  }

  override redraw(results: ReportQueryResults): void {
    if(results.length == 0 || !results[0][this.query.measures[0]]) {
      this.formattedText = new ReportFormattedText("", "");
      return;
    }

    const text = results[0][this.query.measures[0]].toString();
    this.formattedText = this.chart.numberOptions.format.formatValue(text);
    this.color = ReportPanelThreshold.getColor(parseFloat(text), this.chart.numberOptions.format.thresholds) || "black";
  }

}
