
<div *ngIf="includeHeaderAndFooter">
  <div *ngFor="let page of settings.pages; index as pageNumber" class="page" [attr.size]="settings.toolbar.defaultPageSize" [attr.layout]="settings.toolbar.defaultPageLayout">
    <app-report-header 
      [ownerId]="settings.toolbar.owner.id"
      [title]="settings.toolbar.title" 
      [startDate]="settings.filters.startDate"
      [endDate]="settings.filters.endDate"
      [showToolbar]="false">
    </app-report-header>
    <div [id]="'report-page-' + page.id" class="page-content" [attr.size]="page.size" [attr.layout]="page.layout">
      <app-report-layout-viewer 
        #reportLayoutViewer
        [globalFilters]="settings.filters"
        [page]="page">
      </app-report-layout-viewer>
      <dx-load-panel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#report-page-' + page.id }"
        [container]="'#report-page-' + page.id"
        [visible]="page.loading"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="false"
        [hideOnOutsideClick]="false">
      </dx-load-panel>
    </div>
    <app-report-footer [uuid]="settings.uuid" [currentPage]="pageNumber + 1" [totalPages]="settings.pages.length"></app-report-footer>
  </div>
</div>
<div *ngIf="!includeHeaderAndFooter">
  <div *ngFor="let page of settings.pages; index as pageNumber;" [id]="'report-page-' + page.id">
    <div [id]="'report-page-' + page.id" class="page-content" [attr.size]="page.size" [attr.layout]="page.layout">
      <app-report-layout-viewer 
        #reportLayoutViewer
        [globalFilters]="settings.filters"
        [page]="page">
      </app-report-layout-viewer>
      <dx-load-panel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#report-page-' + page.id }"
        [container]="'#report-page-' + page.id"
        [visible]="page.loading"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="false"
        [hideOnOutsideClick]="false">
      </dx-load-panel>
    </div>
    <div style="page-break-after: always;"></div>
  </div>
</div>