import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-report-footer',
  templateUrl: './report-footer.component.html',
  styleUrls: ['./report-footer.component.scss']
})
export class ReportFooterComponent implements OnInit {

  @Input()
  today: DateTime = DateTime.now();

  @Input()
  uuid: string = '12345678-1234-5678-1234-567812345678';

  @Input()
  currentPage: number = 1;

  @Input()
  totalPages: number = 1;

  @Input()
  pagingForIronPdf: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
