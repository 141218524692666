<app-report-panel-numeral #panel
    *ngIf="chart.isNumeral()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-numeral>
<app-report-panel-bars #panel
    *ngIf="chart.isBars()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-bars>
<app-report-panel-pie #panel
    *ngIf="chart.isPie() || chart.isDoughnut()"
    [type]="chart.isPie() ? 'pie' : 'doughnut'"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-pie>
<app-report-panel-gauge-circular #panel
    *ngIf="chart.isCircularGauge()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-gauge-circular>
<app-report-panel-gauge-linear #panel
    *ngIf="chart.isLinearGauge()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-gauge-linear>
<app-report-panel-gauge-bar #panel
    *ngIf="chart.isBarGauge()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-gauge-bar>
<app-report-panel-radar #panel
    *ngIf="chart.isRadar()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-radar>
<app-report-panel-table #panel
    *ngIf="chart.isGrid()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-table>
<app-report-panel-line #panel
    *ngIf="chart.isTimeSeries()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-line>
<app-report-panel-empty #panel
    *ngIf="chart.isEmpty()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-empty>
<app-report-panel-text #panel
    *ngIf="chart.isText()"
    [title]="title"
    [chart]="chart"
    [query]="query"
    [globalFilters]="globalFilters"
    [results]="results">
</app-report-panel-text>