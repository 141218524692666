<dx-pie-chart #chart class="panel-pie" [dataSource]="data" palette="pastel" resolveLabelOverlapping="shift" [type]="type" *ngIf="query.dimensions.length == 1">
  <dxo-common-series-settings [argumentField]="query.dimensions[0]">
      <dxo-label [visible]="true" format="largeNumber" [customizeText]="customizeLabel"></dxo-label>
  </dxo-common-series-settings>
  <dxi-series *ngFor="let m of query.measures;" [valueField]="m" [name]="m"></dxi-series>
  <dxo-legend [customizeText]="customizeLegend" orientation="horizontal" itemTextPosition="right" horizontalAlignment="center" verticalAlignment="bottom"></dxo-legend>
  <dxo-animation easing="easeOutCubic" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
  <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip" [zIndex]="10000"></dxo-tooltip>
</dx-pie-chart>

<dx-pie-chart #chart class="panel-pie" [dataSource]="data" palette="pastel" resolveLabelOverlapping="shift" [type]="type" *ngIf="query.dimensions.length == 0" [customizePoint]="customizePoint">
  <dxo-common-series-settings argumentField="arg" valueField="val">
      <dxo-label [visible]="true" format="largeNumber" [customizeText]="customizeLabel"></dxo-label>
  </dxo-common-series-settings>
  <dxi-series></dxi-series>
  <dxo-legend [customizeText]="customizeText" orientation="horizontal" itemTextPosition="right" horizontalAlignment="center" verticalAlignment="bottom"></dxo-legend>
  <dxo-animation easing="easeOutCubic" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
  <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip" [zIndex]="10000"></dxo-tooltip>
</dx-pie-chart>
