import { Component, Input, enableProdMode, SimpleChanges, OnInit, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';
import { ReportPanelSettings, ReportChart, ReportQuery, ReportQueryResults, ReportFilters } from '../report.service';

export interface ReportPanelInterface {
  export(): void;
  clear(): void;
}

/**
 * Componente base para todos os componentes de painel do report.
 */
@Component({
  selector: '',
  styleUrls: [],
  template: ''
})
export class ReportPanelBaseComponent implements ReportPanelInterface, OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input()
  title: string = 'Meu Painel';

  @Input()
  globalFilters: ReportFilters = new ReportFilters();

  @Input()
  chart: ReportChart = new ReportChart();

  @Input()
  query: ReportQuery = new ReportQuery();

  @Input()
  results: ReportQueryResults = [];

  constructor() { 
    
  }

  ngOnInit(): void {
    if(Array.isArray(this.results))
    {
      this.redraw(JSON.parse(JSON.stringify(this.results)));
    }
  }

  ngOnDestroy(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) 
    {
      if(!changes.hasOwnProperty(propName))
        continue;

      if(changes[propName].firstChange)
        continue;
      
      switch (propName)
      {
        case 'results': 
        {
          if(Array.isArray(changes[propName].currentValue))
          {
            const results = JSON.parse(JSON.stringify(changes[propName].currentValue));
            this.redraw(results);
          }
          break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    
  }

  export(): void {
    
  }

  clear(): void {

  }

  protected redraw(results: ReportQueryResults): void {
    
  }

}
