<div class="page-footer">
  <div class="page-horizonal-line"></div>
  <div class="page-footer-content">
    <dx-box direction="row" width="100%" height="100%" crossAlign="center">
      <dxi-item [visible]="true" [ratio]="3">
        <span style="text-align: left; width: 100%; padding: 0px 0px 0px 10px;">{{today.toJSDate() | date:'dd/MM/yyyy HH:mm:ss'}}</span>
      </dxi-item>
      <dxi-item [visible]="true" [ratio]="5">
        <span style="text-align: center; width: 100%;">{{uuid.toUpperCase()}}</span>
      </dxi-item>
      <dxi-item [visible]="true" [ratio]="3">
        <span *ngIf="!pagingForIronPdf" style="text-align: right; width: 100%; padding: 0px 10px 0px 0px;">Página {{currentPage}} de {{totalPages}}</span>
        <span *ngIf="pagingForIronPdf"  style="text-align: right; width: 100%; padding: 0px 10px 0px 0px;">{{ "Página {page} de {total-pages}" }}</span>
      </dxi-item>
    </dx-box>
  </div>
</div>