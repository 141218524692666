import { Component } from '@angular/core';
import { ReportPanelBaseComponent } from '../report-panel-base.component';
import { ReportQueryResults } from '../../report.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { measures, dimensions, timeDimensions, ReportPanelThreshold, ReportPanelGridFooterTypes, ReportValueTypes } from '../../report.service';

@Component({
  selector: 'app-report-panel-table',
  templateUrl: './report-panel-grid.component.html',
  styleUrls: ['./report-panel-grid.component.scss']
})
export class ReportPanelGridComponent extends ReportPanelBaseComponent {

  data: any[] = [];
  columns: any[] = [];

  constructor() { 
    super();
  }

  override export(): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.title);

    worksheet.columns = this.columns.map((column) => {
      return { header: column.caption, key: column.dataField, width: 20 };
    });

    this.data.forEach((row: any) => {
      worksheet.addRow(row);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.title}.xlsx`);
    });
  }

  override clear(): void {
    this.data = [];
    this.columns = [];
  }

  override redraw(results: ReportQueryResults): void {
    if(results.length == 0) {
      this.data = [];
      this.columns = [];
      return;
    }
    this.data = results;
    // Criamos as colunas na ordem: timeDimensions, dimensions, measures
    this.columns = [
      ...Object.keys(results[0]).map((key) => {
        const timDim = timeDimensions.find((timeDimension) => timeDimension.value == key);
        if(timDim) {
          return {
            dataField: key,
            caption: timDim.label || key,
            format: timDim.format
          };
        }
        return null;
      }),
      ...Object.keys(results[0]).map((key) => {
        const dim = dimensions.find((dimension) => dimension.value == key);
        if(dim) {
          return {
            dataField: key,
            caption: dim.label || key,
            format: dim.format
          };
        }
        return null;
      }),
      ...Object.keys(results[0]).map((key) => {
        const measure = measures.find((measure) => measure.value == key);
        if(measure) {
          return {
            dataField: key,
            caption: measure.label || key,
            format: measure.format
          };
        }
        return null;
      })
    ]
    // Filtramos nulos
    .filter((column) => column);
    console.log(this.data);
    console.log(this.columns);
  }

  getColor(column: any, row: any): string {
    var value;
    if(row[column.dataField] === "true")
      value = 1;
    else if(row[column.dataField] === "false")
      value = 0;
    else
      value = row[column.dataField];

    return ReportPanelThreshold.getColor(value, column.format.thresholds) || "black";
  }

  getText(column: any, row: any): string {
    const format = column.format.formatValue(row[column.dataField]?.toString() ?? "");
    return `${format.value} ${format.unit}`.trim();
  }

  getFooterLabel() : string {
    switch(this.chart.gridOptions.footerType) {
      case ReportPanelGridFooterTypes.NONE:
        return "";
      case ReportPanelGridFooterTypes.AVERAGE:
        return "dx-icon-range";
      case ReportPanelGridFooterTypes.MAXIMUM:
        return "dx-icon-upload";
      case ReportPanelGridFooterTypes.MINIMUM:
        return "dx-icon-download";
      case ReportPanelGridFooterTypes.TOTAL:
        return "dx-icon-plus";
      default:
        return "";
    }
  }

  getFooterText(column: any): string {
    
    // Verifica se o tipo de dado é numérico
    switch(column.format.valueType) {
      case ReportValueTypes.NUMBER:
      case ReportValueTypes.DURATION:
      case ReportValueTypes.HOURMETER:
      case ReportValueTypes.PERCENTAGE_0_100:
      case ReportValueTypes.PERCENTAGE_0_1:
      case ReportValueTypes.CURRENCY:
        break;
      default:
        return "";
    }

    const values = this.data.map((row) => row[column.dataField]);
    let value = null;
    switch(this.chart.gridOptions.footerType) {
      case ReportPanelGridFooterTypes.NONE:
        break;
      case ReportPanelGridFooterTypes.AVERAGE:
        value = values.reduce((acc, value) => acc + parseFloat(value), 0) / values.length;
        break;
      case ReportPanelGridFooterTypes.MAXIMUM:
        value = Math.max(...values);
        break;
      case ReportPanelGridFooterTypes.MINIMUM:
        value = Math.min(...values);
        break;
      case ReportPanelGridFooterTypes.TOTAL:
        value = values.reduce((acc, value) => acc + parseFloat(value), 0);
        break;
      default:
        break;
    }
    const format = column.format.formatValue(value?.toString() ?? "");
    return `${format.value} ${format.unit}`.trim();
  }
}
