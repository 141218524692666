import { Component, ViewChild, enableProdMode, Input, AfterViewInit, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';

import { ReportPanelModels, ReportPanelSettings, ReportChart, ReportQuery, ReportQueryResults } from '../report.service';
import { ReportPanelBaseComponent, ReportPanelInterface } from './report-panel-base.component';
import { ReportPanelNumeralComponent } from './report-panel-numeral/report-panel-numeral.component';
import { ReportPanelEmptyComponent } from './report-panel-empty/report-panel-empty.component';
import { ReportPanelBarsComponent } from './report-panel-bars/report-panel-bars.component';
import { ReportPanelPieComponent } from './report-panel-pie/report-panel-pie.component';
import { ReportPanelGaugeLinearComponent } from './report-panel-gauge-linear/report-panel-gauge-linear.component';
import { ReportPanelGaugeCircularComponent } from './report-panel-gauge-circular/report-panel-gauge-circular.component';
import { ReportPanelGaugeBarComponent } from './report-panel-gauge-bar/report-panel-gauge-bar.component';
import { ReportPanelRadarComponent } from './report-panel-radar/report-panel-radar.component';
import { ReportPanelLineComponent } from './report-panel-line/report-panel-line.component';
import { ReportPanelGridComponent } from './report-panel-grid/report-panel-grid.component';
import { ReportPanelTextComponent } from './report-panel-text/report-panel-text.component';

@Component({
  selector: 'app-report-panel-wrapper',
  templateUrl: './report-panel-wrapper.component.html',
  styleUrls: ['./report-panel-wrapper.component.scss']
})
export class ReportPanelWrapperComponent extends ReportPanelBaseComponent  { 
  
  @ViewChild(ReportPanelNumeralComponent, { static: false }) 
  numeralPanel!: ReportPanelNumeralComponent;

  @ViewChild(ReportPanelBarsComponent, { static: false })
  barsPanel!: ReportPanelBarsComponent;

  @ViewChild(ReportPanelPieComponent, { static: false })
  piePanel!: ReportPanelPieComponent;

  @ViewChild(ReportPanelGaugeLinearComponent, { static: false })
  gaugeLinearPanel!: ReportPanelGaugeLinearComponent;

  @ViewChild(ReportPanelGaugeCircularComponent, { static: false })
  gaugeCircularPanel!: ReportPanelGaugeCircularComponent;

  @ViewChild(ReportPanelGaugeBarComponent, { static: false })
  gaugeBarPanel!: ReportPanelGaugeBarComponent;

  @ViewChild(ReportPanelRadarComponent, { static: false })
  radarPanel!: ReportPanelRadarComponent;

  @ViewChild(ReportPanelGridComponent, { static: false })
  gridPanel!: ReportPanelGridComponent;

  @ViewChild(ReportPanelLineComponent, { static: false })
  linePanel!: ReportPanelLineComponent;  

  @ViewChild(ReportPanelEmptyComponent, { static: false })
  emptyPanel!: ReportPanelEmptyComponent;  

  @ViewChild(ReportPanelEmptyComponent, { static: false })
  textPanel!: ReportPanelTextComponent;  

  @Input()
  id: string = '';

  constructor() { 
    super();
  }

  override clear(): void {
    switch(this.chart.model)
    {
      case ReportPanelModels.NUMERAL:
        this.numeralPanel?.clear();
        break;
      case ReportPanelModels.BARS:
        this.barsPanel?.clear();
        break;
      case ReportPanelModels.PIE:
      case ReportPanelModels.DOUGHNUT:
        this.piePanel?.clear();
        break;
      case ReportPanelModels.LINEAR_GAUGE:
        this.gaugeLinearPanel?.clear();
        break;
      case ReportPanelModels.CIRCULAR_GAUGE:
        this.gaugeCircularPanel?.clear();
        break;
      case ReportPanelModels.BAR_GAUGE:
        this.gaugeBarPanel?.clear();
        break;
      case ReportPanelModels.RADAR:
        this.radarPanel?.clear();
        break;
      case ReportPanelModels.GRID:
        this.gridPanel?.clear();
        break;
      case ReportPanelModels.TIME_SERIES:
        this.linePanel?.clear();
        break;
      case ReportPanelModels.EMPTY:
        this.emptyPanel?.clear();
        break;
      case ReportPanelModels.TEXT:
        this.textPanel?.clear();
        break;
      default:
        break;
    }
  }

  override export(): void {
    switch(this.chart.model)
    {
      case ReportPanelModels.NUMERAL:
        this.numeralPanel?.export();
        break;
      case ReportPanelModels.BARS:
        this.barsPanel?.export();
        break;
      case ReportPanelModels.PIE:
      case ReportPanelModels.DOUGHNUT:
        this.piePanel?.export();
        break;
      case ReportPanelModels.LINEAR_GAUGE:
        this.gaugeLinearPanel?.export();
        break;
      case ReportPanelModels.CIRCULAR_GAUGE:
        this.gaugeCircularPanel?.export();
        break;
      case ReportPanelModels.BAR_GAUGE:
        this.gaugeBarPanel?.export();
        break;
      case ReportPanelModels.RADAR:
        this.radarPanel?.export();
        break;
      case ReportPanelModels.GRID:
        this.gridPanel?.export();
        break;
      case ReportPanelModels.TIME_SERIES:
        this.linePanel?.export();
        break;
      case ReportPanelModels.EMPTY:
        this.emptyPanel?.export();
        break;
      case ReportPanelModels.TEXT:
        this.textPanel?.export();
        break;
      default:
        break;
    }
  }
  
}
