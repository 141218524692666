<dx-scroll-view [scrollByContent]="true" showScrollbar="onScroll" [bounceEnabled]="false">
  <dx-form
    #form
    [readOnly]="false"
    labelMode="outside"
    [showColonAfterLabel]="false"
    labelLocation="left"
    [minColWidth]="300"
    [colCount]="7"
    [(formData)]="settings"
    (onFieldDataChanged)="onFieldDataChanged($event)"
    >
    <dxi-item itemType="group" [colSpan]="3" [colCount]="1" caption="Painel">
      <dxi-item itemType="group" [colCount]="2">
          <dxi-item dataField="title" [colSpan]="2">
              <dxo-label template="Título"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.model" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.modelOptions">
              <dxo-label template="Modelo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.cube" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.sourceOptions">
            <dxo-label template="Base"></dxo-label>
          </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [colCount]="1" caption="Visualização">
        <dxi-item itemType="group">
          <dx-box direction="col" style="margin: auto; width: 90%;" class="dx-card" [height]="430">
            <dxi-item [ratio]="0" [baseSize]="50">
                <div class="panel-title">{{ settings.title }}</div>
            </dxi-item>
            <dxi-item [ratio]="1">
              <div id="previewPanel" class="panel-container">
                <app-report-panel-wrapper #previewPanel
                  [title]="settings.title"
                  [chart]="settings.chart"
                  [query]="settings.query"
                  [globalFilters]="globalFilters"
                  [results]="settings.results">
                </app-report-panel-wrapper>
              </div>
              <dx-load-panel
                shadingColor="rgba(0,0,0,0.4)"
                [position]="{ of: '#previewPanel' }"
                [container]="'#previewPanel'"
                [visible]="settings.loading"
                [showIndicator]="true"
                [showPane]="true"
                [shading]="false"
                [hideOnOutsideClick]="false">
              </dx-load-panel>
            </dxi-item>
          </dx-box>
        </dxi-item>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colSpan]="4" [colCount]="1" caption="Parâmetros">
      <dxi-item itemType="group" cssClass="params-container">
        <!-- Empty Panel Options -->
        <dxi-item itemType="group" [colCount]="1" caption="" *ngIf="settings.chart.isEmpty()">
        </dxi-item>

        <!-- Numeral Panel Options -->
        <dxi-item itemType="group" [colCount]="1" caption="" *ngIf="settings.chart.isNumeral()">
          <dxi-item dataField="query.measures[0]" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="3" caption="" *ngIf="settings.chart.isNumeral()">
          <dxi-item dataField="chart.numberOptions.format.valueType" [colSpan]="1" editorType="dxSelectBox" [editorOptions]="editor.numberOptions.valueTypes">
              <dxo-label template="Formato"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.numberOptions.format.decimalPlaces" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.numberOptions.decimalPlaces">
            <dxo-label template="Decimais"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.numberOptions.format.unit" editorType="dxTextBox" [colSpan]="1" *ngIf="settings.chart.numberOptions.format.valueType === 'Number'">
            <dxo-label template="Unidade"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.numberOptions.format.currency" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.numberOptions.currencyTypes" *ngIf="settings.chart.numberOptions.format.valueType === 'Currency'">
            <dxo-label template="Moeda"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Limiares" [colCount]="3"  *ngIf="settings.chart.isNumeral()">
          <ng-container *ngFor="let thresh of settings.chart.numberOptions.format.thresholds; let i = index">
            <dxi-item [dataField]="'chart.numberOptions.format.thresholds[' + i + '].start'" editorType="dxNumberBox" [editorOptions]="editor.numberOptions.threshNumber">
              <dxo-label text="Início"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.numberOptions.format.thresholds[' + i + '].end'" editorType="dxNumberBox" [editorOptions]="editor.numberOptions.threshNumber">
              <dxo-label text="Fim"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.numberOptions.format.thresholds[' + i + '].color'" editorType="dxColorBox">
              <dxo-label text="Cor"></dxo-label>
            </dxi-item>
          </ng-container>
        </dxi-item>
        
        <!-- Pie Panel Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isPie() || settings.chart.isDoughnut()">
          <dxi-item dataField="query.measures" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
        </dxi-item>

        <!-- Bar Panel Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isBars()">
          <dxi-item dataField="query.measures[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
        </dxi-item>

        <!-- Linear Gauge Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isLinearGauge()">
          <dxi-item dataField="query.measures[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.linearGaugeOptions.min" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
              <dxo-label template="Mínimo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.linearGaugeOptions.max" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Máximo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.linearGaugeOptions.step" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Passo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.linearGaugeOptions.unit" editorType="dxTextBox" [colSpan]="1">
            <dxo-label template="Unidade"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.linearGaugeOptions.orientation" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.gaugeOptions.linearOptions.orientationOptions">
            <dxo-label template="Orientação"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Limiares" [colCount]="3"  *ngIf="settings.chart.isLinearGauge()">
          <ng-container *ngFor="let thresh of settings.chart.linearGaugeOptions.thresholds; let i = index">
            <dxi-item [dataField]="'chart.linearGaugeOptions.thresholds[' + i + '].start'" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.linearOptions.threshNumber">
              <dxo-label text="Início"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.linearGaugeOptions.thresholds[' + i + '].end'" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.linearOptions.threshNumber">
              <dxo-label text="Fim"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.linearGaugeOptions.thresholds[' + i + '].color'" editorType="dxColorBox" [editorOptions]="editor.gaugeOptions.colorOptions">
              <dxo-label text="Cor"></dxo-label>
            </dxi-item>
          </ng-container>
        </dxi-item>

        <!-- Circular Gauge Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isCircularGauge()">
          <dxi-item dataField="query.measures[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.circularGaugeOptions.min" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
              <dxo-label template="Mínimo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.circularGaugeOptions.max" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Máximo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.circularGaugeOptions.step" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Passo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.circularGaugeOptions.unit" editorType="dxTextBox" [colSpan]="1">
            <dxo-label template="Unidade"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Limiares" [colCount]="3"  *ngIf="settings.chart.isCircularGauge()">
          <ng-container *ngFor="let thresh of settings.chart.circularGaugeOptions.thresholds; let i = index">
            <dxi-item [dataField]="'chart.circularGaugeOptions.thresholds[' + i + '].start'" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.circularOptions.threshNumber">
              <dxo-label text="Início"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.circularGaugeOptions.thresholds[' + i + '].end'" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.circularOptions.threshNumber">
              <dxo-label text="Fim"></dxo-label>
            </dxi-item>
            <dxi-item [dataField]="'chart.circularGaugeOptions.thresholds[' + i + '].color'" editorType="dxColorBox" [editorOptions]="editor.gaugeOptions.colorOptions">
              <dxo-label text="Cor"></dxo-label>
            </dxi-item>
          </ng-container>
        </dxi-item>

        <!-- Bar Gauge Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isBarGauge()">
          <dxi-item dataField="query.measures[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.barGaugeOptions.min" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
              <dxo-label template="Mínimo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.barGaugeOptions.max" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Máximo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.barGaugeOptions.step" [colSpan]="1" editorType="dxNumberBox" [editorOptions]="editor.gaugeOptions.intValues">
            <dxo-label template="Passo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.barGaugeOptions.unit" editorType="dxTextBox" [colSpan]="1" [editorOptions]="editor.gaugeOptions.colorOptions">
            <dxo-label template="Unidade"></dxo-label>
          </dxi-item>
        </dxi-item>

        <!-- Radar Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isRadar()">
          <dxi-item dataField="query.measures" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.radarOptions.type" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.radarOptions.typesOptions">
            <dxo-label template="Tipo"></dxo-label>
          </dxi-item>
        </dxi-item>

        <!-- Grid Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isGrid()">
          <dxi-item dataField="query.measures" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isGrid()">
          <dxi-item dataField="query.timeDimensions[0].granularity" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.granularityOptions" *ngIf="settings.query.usesTimeDimensions()">
            <dxo-label template="Agrupar"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="3" caption="" *ngIf="settings.chart.isGrid()">
          <dxi-item dataField="chart.gridOptions.showRowNumber" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.tableOptions.showRowNumberOptions">
            <dxo-label template="Numerar Linhas?"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.gridOptions.footerType" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.tableOptions.footerTypeOptions">
            <dxo-label template="Rodapé"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="" [colCount]="2" *ngIf="settings.chart.isGrid()" style="white-space: nowrap;">
          <dxi-item itemType="group" [colCount]="1" caption="Dimensões">
            <div class="dx-card sortable-container">
              <dx-scroll-view [scrollByContent]="true" showScrollbar="always" [bounceEnabled]="false" [height]="270">
                <dx-sortable class="sortable-list" group="dimensions" [data]="settings.query.dimensions" dropFeedbackMode="indicate" (onDragStart)="onSortableDragStart($event)" (onAdd)="onSortableAdd($event)" (onRemove)="onSortableRemove($event)" (onReorder)="onSortableReorder($event)">
                  <div *ngFor="let col of settings.query.dimensions">
                    <dx-box direction="row" class="dx-card sortable-card" [height]="35">
                      <dxi-item [ratio]="0" [baseSize]="15"><i class="dx-icon-dragvertical"></i></dxi-item>
                      <dxi-item [ratio]="1">{{ getDimensionName(col) }}</dxi-item>
                    </dx-box>
                  </div>
                </dx-sortable>
              </dx-scroll-view>
            </div>
          </dxi-item>
          <dxi-item itemType="group" [colCount]="1" caption="Medições">
            <div class="dx-card sortable-container">
              <dx-scroll-view [scrollByContent]="true" showScrollbar="always" [bounceEnabled]="false" [height]="270">
                <dx-sortable class="sortable-list" group="measures" [data]="settings.query.measures" dropFeedbackMode="indicate" (onDragStart)="onSortableDragStart($event)" (onAdd)="onSortableAdd($event)" (onRemove)="onSortableRemove($event)" (onReorder)="onSortableReorder($event)">
                  <div *ngFor="let col of settings.query.measures">
                    <dx-box direction="row" class="dx-card sortable-card" [height]="35">
                      <dxi-item [ratio]="0" [baseSize]="15"><i class="dx-icon-dragvertical"></i></dxi-item>
                      <dxi-item [ratio]="1">{{ getMeasureName(col) }}</dxi-item>
                    </dx-box>
                  </div>
                </dx-sortable>
              </dx-scroll-view>
            </div>
          </dxi-item>
        </dxi-item>

        <!-- Line Panel Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isTimeSeries()">
          <dxi-item dataField="query.measures" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions[0]" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.lineOptions.type" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.lineOptions.typesOptions">
            <dxo-label template="Tipo"></dxo-label>
          </dxi-item>
          <dxi-item dataField="chart.lineOptions.displayMode" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.lineOptions.displayModeOptions">
            <dxo-label template="Valores"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.timeDimensions[0].granularity" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.granularityOptions" *ngIf="settings.query.usesTimeDimensions()">
            <dxo-label template="Agrupar"></dxo-label>
          </dxi-item>
        </dxi-item>

        <!-- Text Panel Options -->
        <dxi-item itemType="group" [colCount]="2" caption="" *ngIf="settings.chart.isText()">
          <dxi-item dataField="query.measures" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.measuresOptions">
            <dxo-label template="Medição"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.dimensions" editorType="dxTagBox" [colSpan]="1" [editorOptions]="editor.dimensionsOptions">
            <dxo-label template="Por"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="4" caption="" *ngIf="settings.chart.isText()">
          <dxi-item dataField="query.timeDimensions[0].granularity" editorType="dxSelectBox" [colSpan]="1" [editorOptions]="editor.granularityOptions" *ngIf="settings.query.usesTimeDimensions()">
            <dxo-label template="Agrupar"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.segments" editorType="dxTagBox" [colSpan]="2" [editorOptions]="editor.segmentsOptions">
            <dxo-label template="Filtros"></dxo-label>
          </dxi-item>
          <dxi-item dataField="query.limit" editorType="dxNumberBox" [colSpan]="1" [editorOptions]="editor.limitOptions">
            <dxo-label template="Limite"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="1" caption="" *ngIf="settings.chart.isText()">
          <dxi-item dataField="chart.textOptions.text" editorType="dxHtmlEditor" [colSpan]="1" [editorOptions]="editor.htmlEditorOptions">
            <dxo-label template="Texto"></dxo-label>
          </dxi-item>
        </dxi-item>
        

        <!-- TODO:  Panel Options -->

      </dxi-item>
    </dxi-item>
  </dx-form>
</dx-scroll-view>